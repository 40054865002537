import React from "react";
import { Firebase, Firestore } from "config/firebase";

export const userModel = {
  model_version: 1.0,
  disabled: false,
  name_first: "",
  name_last: "",
  avatar: "/images/default-avatar.png",
  username: "",
  roles: 0x1,  /* standard user */
  authenticated_user: false,
  contact: {
    email: "",
    email_validated: false,
    phone: "",
    phone_validated: false,
    preferred_contact_method: "email"
  },
  social_media: {
    facebook: "",
    github: "",
    google_plus: "",
    linkedin: "",
    twitter: ""
  },
  timestamp: {
    email_validated: 0,
    join: 0,
    last_login: 0
  }
}

export const UserContext = React.createContext();

export class UserContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = userModel;
    this.uid = null;
    this.contextChanged = false;
    this.userIsMe = true;
    this.validatedFields = {};
    this.formValidated = true;
    this.loadedUser = false;
    this.onProcessing = this.props.onProcessing;
    this.onProcessingComplete = this.props.onProcessingComplete;

    if (this.props.user) {
      // use user object
      this.userIsMe = false;
      this.uid = this.props.uid;
      this.loadedUser = true;
      let user = this.props.user;
      this.state = { ...user };
    } else if (this.props.uid) {
      // use props uid?
      this.userIsMe = false;
      if (this.props.uid === "0") {
        this.loadedUser = true;
      } else {
        this.loadedUser = false;
        this.uid = this.props.uid;
      }
    } else {
      // user current user?
      let user = Firebase.auth().currentUser;
      if (user) {
        this.uid = user.uid;
        this.userIsMe = true;
      } else {
        this.uid = null;
      }

      // given we are managing auth user, lets track any changes for here on...
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.uid = user.uid;
          this.getUser().then((user) => {
            // all good
          }).catch((error) => {
            console.log(error)
          });
        } else {
          this.uid = null;
        }
      })
    }
  }

  componentDidMount = () => {
    if (!this.loadedUser) {
      this.getUser().then((user) => {
        // all good
        this.loadedUser = true;
      }).catch((error) => {
        console.log(error)
      });
    }
  }

  getUser = () => {
    // get user from firebase
    if (this.onProcessing) this.onProcessing();
    return new Promise((resolve, reject) => {
      Firestore.collection("users").doc(this.uid).get().then((doc) => {
        if (doc.exists) {
          let user = doc.data();
          this.setState({ ...user }, () => {
            if (this.onProcessingComplete) this.onProcessingComplete();
            resolve(this.state)
          });
        } else {
          if (this.onProcessingComplete) this.onProcessingComplete();
          reject("Unable to load user details for user: %s", this.uid);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }

  setContextState = (key, value, callback) => {
    if (key.indexOf(".") === -1) {
      this.setState({ [key]: value }, () => {
        if (callback)
          callback();
      });
    } else {
      let user = this.state;
      let objname = key.split(".")[0];
      let name = key.split(".")[1];
      user[objname] = { ...user[objname], [name]: value };
      this.setState({ ...user }, () => {
        if (callback)
          callback();
      });
    }
    this.contextChanged = true;
  }

  handleChange = (e) => {
    this.setContextState(e.target.name, e.target.value);
  }

  validateField = (key) => {
    // validate field
    let validated = false;
    if (key.indexOf("email") !== -1) {
      validated = (this.state.contact.email.indexOf("@") !== -1 && this.state.contact.email.indexOf(".") !== -1);
    } else {
      let user = this.state;
      let v = user[key];
      validated = v.length > 0;
    }

    // check all validated fields
    this.validatedFields[key] = validated;
    let allValidated = true;
    for (var k in this.validatedFields) {
      if (this.validatedFields[k] === false)
        allValidated = false;
    }
    this.formValidated = allValidated;

    // done... return class
    if (validated)
      return "has-label has-success";
    return "has-label has-danger";
  }

  commitContext = () => {
    if (this.onProcessing) this.onProcessing();
    return new Promise((resolve, reject) => {
      // write
      if (this.uid) {
        Firestore.collection("users").doc(this.uid).set(this.state).then(() => {
          this.contextChanged = false;
          // make sure we update users display name, profile url and disabled
          this.updateAuthenticationProperties(this.state.username, this.state.avatar, this.state.disabled);
          if (this.onProcessingComplete) this.onProcessingComplete();
          this.setState({ ...this.state })  // force state refresh
          resolve(this.state);
        }).catch((error) => {
          reject(error);
        });
      } else {
        Firestore.collection("users").add(this.state).then((doc) => {
          this.uid = doc.id;
          this.contextChanged = false;
          // make sure we update users display name, profile url and disabled
          this.updateAuthenticationProperties(this.state.username, this.state.avatar, this.state.disabled);
          if (this.onProcessingComplete) this.onProcessingComplete();
          this.setState({ ...this.state })  // force state refresh
          resolve(this.state);
        }).catch((error) => {
          reject(error);
        });
      }
    })
  }

  resetContext = () => {
    this.contextChanged = false;
    this.validatedFields = {};
    this.formValidated = true;
    return this.getUser();
  }

  updateAuthenticationProperties = (name, url, disabled) => {
    this.setState({ displayName: name });
    let user = Firebase.auth().currentUser;
    if (user && user.uid === this.uid) {
      user.updateProfile({
        displayName: name,
        photoURL: url,
        disabled: disabled
      }).then(() => {
        // all good
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  getUserLicenses = (productId, state) => {
    // THIS HAS NOT BEEN IMPLEMENTED
    // state is "-1" for all, "0" for available (not activated), or "1" for activated
    let licenses = [];
    return new Promise((resolve, reject) => {
      Firestore.collection("users").doc(this.state.uid).collection("licenses").get().then((querySnapshot) => {
        for(let i = 0; i < querySnapshot.docs.length; i++) {
          let doc = querySnapshot.docs[i];
          let license = doc.data();
          if (license.license_type.indexOf("trial") === -1 && license.license_type.indexOf(productId) === 0) {          
            licenses.push(licenses);
          }
        }

        // done
        resolve(licenses)
      }).catch(function (error) {
        reject(error);
      });
    });
  }

  render = () => {
    return (
      <UserContext.Provider value={{
        state: this.state,
        userIsMe: this.userIsMe,
        contextChanged: this.contextChanged,
        formValidated: this.formValidated,        
        setContextState: (key, value, callback) => this.setContextState(key, value, callback),
        handleChange: (e) => this.handleChange(e),
        validateField: (key) => this.validateField(key),
        commitContext: () => this.commitContext(),
        resetContext: () => this.resetContext(),
        getUserLicenses: () => this.getUserLicenses()
      }}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}