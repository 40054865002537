export const Consts = {
  SYSTEM: {
    RELEASE: true,                  /* UPDATE FOR PRODUCTION */
    AUTH_ENABLED: true,             /* UPDATE FOR PRODUCTION */
    WEB_VERSION_STR: "1.4.1.0",     /* UPDATE FOR PRODUCTION */
    WEB_VERSION: 1410,              /* UPDATE FOR PRODUCTION */
    CORE_VERSION: 1402,             /* UPDATE FOR PRODUCTION */
    CORE_VERSION_STR: "1.4.0.2",    /* UPDATE FOR PRODUCTION */
    MIN_CORE_VERSION: 1400,         /* UPDATE FOR PRODUCTION */
    MIN_INSTALLER_VERSION: 2110,
    PRODUCT_ID: "tcc"
  },
  LICENSE: {  /* from service code, keep in sync */
    TYPE: {
      UNDETERMINED: 0,
      ANVIL_FILE_SECURITY: 1,
      THE_CYBER_CANARY: 2,
      DRIVE_BENDER: 3
    },
    STATE: {
      UNDETERMINED: 0,
      ERROR: 1,
      NOTFORMACHINE: 2,
      TRIAL: 3,
      TRIALEXPIRED: 4,
      NONACTIVATEDEXPIRED: 5,
      NEEDACTIVATION: 6,
      SUBSCRIPTIONEXPIRED: 7,
      VALID_LICENSES: 19,
      STANDARD: 20,
      PREMIUM: 25,
      ENTERPRISE_CLIENT: 30,
      ENTERPRISE_SERVER: 35
    }
  },
  WEB_APP_RELEASE: {
    NAME: "Cyber Canary",
    FULL_NAME: "The Cyber Canary",
    INSTALL_ENABLED: true,
    DMUI: "https://files.division-m.com/Cyber-Canary-Installer.exe",
    DMUI_ZIP: "https://files.division-m.com/Cyber-Canary-Installer.zip",
    INSTALL_PACKAGE: "http://files.division-m.com/TCC v1402.package",  /* UPDATE FOR PRODUCTION */ /* DO NOT USE SSL */
    URL: "https://portal.thecybercanary.com"
  },
  WEB_APP_BETA: {
    NAME: "Cyber Canary (Beta)",
    FULL_NAME: "The Cyber Canary (Beta)",
    INSTALL_ENABLED: true,
    DMUI: "https://files.division-m.com/beta/Cyber-Canary-Installer.exe",
    DMUI_ZIP: "https://files.division-m.com/beta/Cyber-Canary-Installer.zip",
    INSTALL_PACKAGE: "http://files.division-m.com/beta/TCC v1402.package",  /* UPDATE FOR PRODUCTION */ /* DO NOT USE SSL */
    URL: "https://portal.thecybercanary.com"
  },
  WEB_APP_DEV: {
    NAME: "Cyber Canary",
    FULL_NAME: "The Cyber Canary",
    INSTALL_ENABLED: true,
    DMUI: "http://localhost:3000/development/Cyber-Canary-Installer.exe",
    DMUI_ZIP: "http://localhost:3000/development/Cyber-Canary-Installer.zip",
    INSTALL_PACKAGE: "http://localhost:3000/development/dev.package",
    URL: "http://localhost:3000"
  },
  WEB_APP_VALUE: {
    NAME: "NAME",
    FULL_NAME: "FULL_NAME",
    INSTALL_ENABLED: true,
    DMUI: "DMUI",
    DMUI_ZIP: "DMUI_ZIP",
    INSTALL_PACKAGE: "INSTALLER_PACKAGE",
  },
  STRIPE: {
    FIELD: {
      PLAN_TYPE: 0,
      PLAN_ID: 1,
      PLAN_NAME: 2,
      PLAN_DEVICES: 3,
      PLAN_PRICE_YEAR: 4,
      PLAN_PRICE_MONTH: 5,
      PLAN_PAY_TYPE: 6,
    },
    DEV: {
      API_KEY: "pk_test_DbHXjxza3RwmpEa05Gp5Us4x",
      PLAN_STANDARD_1: ["tcc_standard", "price_1HSVXmEoJkRwFCgVKPSHr6fg", "Standard plan", "3", "12", "1", "annually"],
      PLAN_STANDARD_2: ["tcc_standard", "price_1HSVXmEoJkRwFCgVs2H43mzx", "Standard plan", "5", "18", "1.50", "annually"],
      PLAN_STANDARD_3: ["tcc_standard", "price_1HSVXmEoJkRwFCgVhaNwYm3l", "Standard plan", "10", "30", "2.5", "annually"],
      PLAN_PREMIUM_1: ["tcc_premium", "price_1HSVeXEoJkRwFCgVfQkWSgE6", "Premium plan", "3", "18", "1.50", "annually"],
      PLAN_PREMIUM_2: ["tcc_premium", "price_1HSVeXEoJkRwFCgVxYd1U0K9", "Premium plan", "5", "27", "2.25", "annually"],
      PLAN_PREMIUM_3: ["tcc_premium", "price_1HSVeYEoJkRwFCgV0cO8PkJM", "Premium plan", "10", "50", "4.17", "annually"],
      PLAN_ENT_CLI_1: ["tcc_enterprise_client", "price_1IORsfEoJkRwFCgVOJTiVPjQ", "Enterprise client plan", "20", "150", "12.50", "monthly"],
      PLAN_ENT_CLI_2: ["tcc_enterprise_client", "price_1IORsfEoJkRwFCgVbWc7IJID", "Enterprise client plan", "50", "300", "25", "monthly"],
      PLAN_ENT_CLI_3: ["tcc_enterprise_client", "price_1IORsfEoJkRwFCgVH8pKAFbL", "Enterprise client plan", "100", "500", "42", "monthly"],
      PLAN_ENT_SVR_1: ["tcc_enterprise_server", "price_1IORtdEoJkRwFCgVU1IuMJs0", "Enterprise server plan", "1", "60", "5", "monthly"],

    },
    REL: {
      API_KEY: "pk_live_GCcCCBz0D1pvXgMYpNzQXzEn",
      PLAN_STANDARD_1: ["tcc_standard", "price_1HVp3rEoJkRwFCgV7Q3DKgrN", "Standard plan", "3", "12", "1", "annually"],
      PLAN_STANDARD_2: ["tcc_standard", "price_1HVp3rEoJkRwFCgVxOiM6SFa", "Standard plan", "5", "18", "1.50", "annually"],
      PLAN_STANDARD_3: ["tcc_standard", "price_1HVp3rEoJkRwFCgVXdywxiUV", "Standard plan", "10", "30", "2.50", "annually"],
      PLAN_PREMIUM_1: ["tcc_premium", "price_1HVp5hEoJkRwFCgVMLvCsEHN", "Premium plan", "3", "18", "1.50", "annually"],
      PLAN_PREMIUM_2: ["tcc_premium", "price_1HVp5hEoJkRwFCgV0QjUD862", "Premium plan", "5", "27", "2.25", "annually"],
      PLAN_PREMIUM_3: ["tcc_premium", "price_1HVp5hEoJkRwFCgVwu6jShsk", "Premium plan", "10", "50", "4.17", "annually"],
      PLAN_ENT_CLI_1: ["tcc_enterprise_client", "price_1INrpBEoJkRwFCgVVljrPi06", "Enterprise client plan", "20", "150", "12.50", "monthly"],
      PLAN_ENT_CLI_2: ["tcc_enterprise_client", "price_1INrpBEoJkRwFCgVzLH4F5qf", "Enterprise client plan", "50", "300", "25", "monthly"],
      PLAN_ENT_CLI_3: ["tcc_enterprise_client", "price_1INrpBEoJkRwFCgVrsDDpLwB", "Enterprise client plan", "100", "500", "42", "monthly"],
      PLAN_ENT_SVR_1: ["tcc_enterprise_server", "price_1INrs3EoJkRwFCgVRV1dHcO2", "Enterprise server plan", "1", "60", "5", "monthly"],
    }
  },
  USER: {
    DEFAULT_HOME: "/",
    LOGIN: "/user/login",
    REGISTER: "user/register"
  },
  RULES: {
    DEFAULT_RULE: "1|New rule|[L+R+W-]|[*]|[*]|{0}"
  },
  MESSAGE_TYPE: {
    OK: "VALIDATE_OK",
    ERROR: "VALIDATE_ERROR",
    INFO: "VALIDATE_INFO",
    CONFIRM: "VALIDATE_CONFIRM"
  },
  ROLES: {
    NONE: 0x0,
    USER: 0x1,
    LICENSE_ADMIN: 0x10,
    ADMIN: 0x100
  },
  OAUTH_PROVIDERS: {
    GOOGLE: "GOOGLE",
    FACEBOOK: "FACEBOOK",
    TWITTER: "TWITTER",
    GITHUB: "GITHUB"
  },
  RULE_ACCESS: {
    NOT_SPECIFIED: 0,
    WRITE_ALLOW: 1,
    WRITE_DENIED: 2,
    WRITE_DENIED_SILENT: 3,
    WRITE_REQUESTED: 4,
    WRITE_IMMUTABLE: 5,
    WRITE_LEARN: 6,
    WRITE_ENCRYPTED: 7,
    READ_ALLOW: 8,
    READ_DENIED: 9,
    LIST_DECRYPTED: 10,
    LIST_ALLOW: 11,
    LIST_DENIED: 12
  },
  RULE_TYPE: {
    WILDCARD_RULE: 1,
    FOLDER_APPS_RULE: 2,
    LEARNING_RULE: 3,
    IMMUTABLE_FOLDER_RULE: 4,
    HOME_FOLDER_RULE: 5,
    CANARY_FILE_RULE: 6,
    ENCRYPTED_FOLDER_RULE: 7,
    THE_CYBER_CANARY_RULE: 8,
    SUPPORT_RULE: 998,
    ORPHANED_SUPPORT_RULE: 999
  },
  RULE_SET_TYPE: {
    WHITELIST: 1,
    BLACKLIST: 2,
    REQUEST_MODE: 3,
    IMMUTABLE_MODE: 4,
    LEARNING_MODE: 5
  },
  PROCESS_REPLACE_ARRAY: ["<*>", "All signed applications", "^*", "All applications under the rule folder(s) or child folder(s)", "^", "Applications under the rule folder(s)", "-", "No Applications", "*", "All applications"] /*order of array is important */
}

export const LicenseAgreementText =
  "The Cyber Canary LICENSE AGREEMENT\n" +
  "\n" +
  "Copyright law and international copyright treaties protect this software.The user must not copy, reproduce, re-engineer, or tamper with this software or related documentation.\n" +
  "Attempting to reverse-engineer, de-compile, or otherwise modify the code of any software or documentation component is forbidden.\n" +
  "\n" +
  "The Cyber Canary DISCLAIMER\n" +
  "\n" +
  "IN NO EVENT WILL Division-M Pty Ltd BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS AND/OR INFORMATION, BUSINESS INTERRUPTION AND THE LIKE ARISING OUT OF THE USE OR THE INABILITY TO USE THIS PRODUCT OR ANY OTHER PRODUCT.\n" +
  "ALL PRODUCTS DEVELOPED BY Division-M Pty Ltd OR RELATED PARTIES ARE COVERED BY THIS AGREEMENT WITHOUT EXCEPTION.\n" +
  "\n" +
  "The Cyber Canary MALWARE PROTECTION DISCLAIMER\n" +
  "\n" +
  "ANY MALWARE PROTECTION FEATURE WITHIN OR PROMOTED BY THIS PRODUCT ARE USED AT THE USERS OWN RISK. Division-M Pty Ltd MAKE NO GUARANTEES WITH REGARDS TO ANY FORM OF MALWARE PROTECTION, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS AND/OR INFORMATION, BUSINESS INTERRUPTION AND THE LIKE ARISING OUT OF THE USE OR THE INABILITY TO USE THIS PRODUCT OR ANY OTHER PRODUCT.\n" +
  "\n" +
  "ALL PRODUCTS DEVELOPED BY Division-M Pty Ltd OR RELATED PARTIES ARE COVERED BY THIS AGREEMENT WITHOUT EXCEPTION.\n" +
  "\n" +
  "\"Product License\" TAMPER AGREEMENT\n" +
  "\n" +
  "Copy protection technologies used within this product perform a number of tasks to ensure product security. Any tampering of the protection system may disable this, or any other software using this protection scheme. Division-M Pty Ltd is under no obligation to repair or restore any damage inflicted by users, registered or otherwise to this protection scheme.\n" +
  "\n" +
  "The Cyber Canary, The Cyber Canary Manager are trademarks of Division-M Pty Ltd.\n" +
  "\n" +
  "All Software Copyright © 2020 Division-M Pty Ltd.\n" +
  "All Rights Reserved.";

export const TermsAndConditionsText =
  "These terms and conditions (\"Terms\", \"Agreement\") are an agreement between Division-M Pty Ltd (\"Division-M Pty Ltd\", \"us\", \"we\" or \"our\") and you (\"User\", \"you\" or \"your\"). This Agreement sets forth the general terms and conditions of your use of https://www.thecybercanary.com or https://portal.thecybercanary.com websites and any of its products or services (collectively, \"Website\" or \"Services\").\n" +
  "\n" +
  "Accounts and membership\n" +
  "If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for the our Services. We may block your email address and Internet protocol address to prevent further registration.\n" +
  "\n" +
  "Billing and payments\n" +
  "You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). If, in our judgment, your purchase constitutes a high risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.\n" +
  "\n" +
  "Accuracy of information\n" +
  "Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related Service has been modified or updated.\n" +
  "\n" +
  "Backups\n" +
  "We are not responsible for Content residing on the Website. In no event shall we be held liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been deleted as of a certain date and time when we may have backed up data for our own purposes. We make no guarantee that the data you need will be available.\n" +
  "\n" +
  "Intellectual property rights\n" +
  "This Agreement does not transfer from Division-M Pty Ltd to you any Division-M Pty Ltd or third-party intellectual property, and all right, title, and interest in and to such property will remain (as between the parties) solely with Division-M Pty Ltd. All trademarks, service marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of Division-M Pty Ltd or Division-M Pty Ltd licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or Services may be the trademarks of other third parties. Your use of our Website and Services grants you no right or license to reproduce or otherwise use any Division-M Pty Ltd or third-party trademarks.\n" +
  "\n" +
  "Limitation of liability\n" +
  "To the fullest extent permitted by applicable law, in no event will Division-M Pty Ltd, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if Division-M Pty Ltd has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Division-M Pty Ltd and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to Division-M Pty Ltd for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.\n" +
  "\n" +
  "Indemnification\n" +
  "You agree to indemnify and hold Division-M Pty Ltd and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website or Services or any willful misconduct on your part.\n" +
  "\n" +
  "Changes and amendments\n" +
  "We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do we will post a notification on the main page of our Website. Continued use of the Website after any such changes shall constitute your consent to such changes.\n" +
  "\n" +
  "Acceptance of these terms\n" +
  "You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services.\n" +
  "\n" +
  "Contacting us\n" +
  "If you have any questions about this Agreement, please contact us.\n" +
  "This document was last updated on September 9th, 2020\n";