import React from 'react';
import NavbarSimple from "../components/navbar-simple"
import PrivacyPolicyBody from '../components/privacy-policy-body';
import HomeFooter from "../components/home-footer";

export const PrivacyPolicy = () => (
  <div className="body_wrapper">
    <NavbarSimple />
    <PrivacyPolicyBody />
    <HomeFooter />
  </div>
)