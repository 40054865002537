import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavbarSimple extends Component {
  render() {    
    return (
      <header className="header_area">
        <nav className="navbar navbar-expand-lg menu_one" style={{ background: "#fff" }}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={require("../img/canary_logo_light.png")} alt="The Cyber Canary" />
              <img src={require("../img/canary_logo_dark.png")} alt="The Cyber Canary" />
            </Link>
            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="menu_toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav menu ml-auto">
                <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                  <a href="/" className="nav-link dropdown-toggle">Home</a>
                </li>                       
              </ul>
              <a className="btn_get btn_hover" href="https://portal.thecybercanary.com" rel="noopener noreferrer">Dashboard</a>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default NavbarSimple;