import React from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom'
import { Consts } from "config/consts";
import { getWebAppValue, isDev } from "config/utilities"
import { ComponentAuthentication } from "./component-authentication"
import { InstallLicenseAgreement } from "./install-license-agreement";
import { InstallTermsAndConditions } from "./install-ts-and-cs";

const displayAuth = 0;
const displayTsAndCs = 1;
const displayDownload = 2;
const displayWaitingRun = 3;
const displayInstalling = 4;
const displayInstallError = 5;
const displayInstallationComplete = 6;

const defaultInstallScriptStandard = 'rule_set_sys_drive<%DISD%>the_cyber_canary<%DISD%>{"TITLE":"The Cyber Canary ransomware protection"<%COMMA%>"EMAIL_ADDRESSES":"user.email@anvil.com"<%COMMA%>"INTRUSION_DETECTION_READ_PERCENT":0<%COMMA%>"DISPLAY_ALERT":true<%COMMA%>"KILL_PROCESS":true<%COMMA%>"SHUTDOWN_SERVER":false<%COMMA%>"HIDE_FROM_EXPLORER":true<%COMMA%>"PROTECT_ALL_USERS":true<%COMMA%>"USER_DEFINE_FOLDERS":""<%COMMA%>"USE_SOHO_FILENAME":false}';

var scroll = require('react-scroll');
var Element = scroll.Element;
var scroller = scroll.scroller;
var lastScrollSection = null;

class InstallBodyPC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      displayState: displayAuth,
      displayLastState: -1,      
      showLicAgree: false,
      showTsAndCs: false,
      installerId: "",
      progress: "waiting...",
      status: ""
    }
  }

  componentDidMount = () => {
    // check installed
    window.roFolderWall.CheckServiceConnection(Consts.SYSTEM.CORE_VERSION, Consts.SYSTEM.MIN_CORE_VERSION, (tokenValid, serviceValid, upgradeState) => {
      console.log("Init check connection, tokenValid: %s, serviceValid: %s, upgradeState: %s", tokenValid, serviceValid, upgradeState);

      // check all ok
      if (tokenValid && serviceValid) {
        console.log("Cyber Canary connected")
        this.setState({
          displayState: displayInstallationComplete
        })
      } else {
        // dont worry, need to install!      
      }
    });
  }

  scrollTo = (section) => {

    if (section === lastScrollSection)
      return;

    let options = {
      smooth: true,            
      offset: -100,
      duration: 500,
      isDynamic: true
    };

    // only allowing 1 scroll atm
    // if (section !== "install-run-installer")
    //   return;
    //console.log("Scrolling to %s", section);
    scroller.scrollTo(section, options);
    lastScrollSection = section;
  }

  handleChange = (e) => {
    // update state
    this.setState({ [e.target.name]: e.target.value }, () => {
      // validate
      this.setState({ credentialsValidated: (this.state.email.indexOf("@") !== -1 && this.state.email.indexOf(".") !== -1 && this.state.password.length > 0) });
    });
  }  

  waitOnEvent = (method, timeout) => {    
    setTimeout(() => {
      method();
    }, timeout);
  }

  onAuthenticated = (user) => {    
    this.setState({
      user: user,
      displayState: this.state.displayState === displayInstallationComplete ? displayInstallationComplete : user ? displayTsAndCs : displayAuth
    }, () => {
      // if (this.state.displayState === displayTsAndCs)
      //   this.scrollTo("install-ts-and-cs");
      // if (this.state.displayState === displayInstallationComplete)
      //   this.scrollTo("install-run-installer");
    })
  }

  closeAgreements = (agree) => {
    this.setState({
      showLicAgree: false,
      showTsAndCs: false      
    })
  }

  agreeTsAndCs = (e) => {
    this.setState({      
      displayLastState: this.state.displayState,
      displayState: displayDownload
    }, () => {
      this.scrollTo("install-download");      
    })
  }

  checkConnector = () => {
    this.setState({
      displayLastState: this.state.displayState,
      displayState: displayWaitingRun
    }, () => this.scrollTo("install-run-installer"))

    // start polling Installer
    console.log("Looking for the installer...")
    window.roDMUniversalInstaller.CheckServiceConnection(() => {
      console.log("Installer connected")

      // move on to install
      this.setState({
        displayLastState: this.state.displayState,
        displayState: displayInstalling
      }, () => this.runInstallation());
    }, () => {
      console.log("Installer connection not found, waiting")

      // wait again
      if (this.state.displayState === displayWaitingRun)  // under dev, install can be flagged complete
        this.waitOnEvent(this.checkConnector, 1000);
    });
  }  

  runInstallation = () => {
    console.log("Running installer...");

    // first add some user defined vars
    const udn = "USER_ID;USER_EMAIL;DEFAULT_INSTALL_SCRIPT";
    const udv =  this.state.user.uid + ";" + this.state.user.email + ";" + defaultInstallScriptStandard;
    window.roDMUniversalInstaller.AddUserDefinedVar(udn, udv, () => {
      window.roDMUniversalInstaller.InstallPackage("The Cyber Canary", getWebAppValue(Consts.WEB_APP_VALUE.INSTALL_PACKAGE), (installerId) => {
        if (installerId) {
          console.log("Installer starting, id: %s", installerId);
          this.setState({
            installerId: installerId
          }, () => {
            this.checkInstallation();
          })
        } else {
          // we did not get an id... installer could already be running
          console.log("We did not obtain an installer id... installer could already be running");
        }
      }, (error) => {
        // error??
        console.log("Error running installer, try again?")
        this.setState({
            status: "There was an error running the installer!",
            displayLastState: this.state.displayState,
            displayState: displayInstallError
          }, () => {
          this.checkConnector();
        });
      });
    }, (error) => {
      // error??
      console.log("Error adding user defined vars?")
      this.setState({
          status: "There was an error running the installer!",
          displayLastState: this.state.displayState,
          displayState: displayInstallError
        }, () => {
        this.checkConnector();
      });
    });
  }

  checkInstallation = () => {
    // check for connection
    window.roDMUniversalInstaller.GetProgressState(this.state.installerId, (status, progress, complete, error) => {
      this.setState({
        progress: !complete ? status + ", " + progress + "% complete..." : error ? "Installation failed!" : "Installation complete!",
        status: status,
      }, () => {
        console.log(this.state.status);
        if (!complete) {
          this.waitOnEvent(this.checkInstallation, 500);
        } else {
          this.completeInstallation();
        }
      })
    }, () => {
      // installer gone
      this.setState({
        status: "Installer is not longer available, it may have been manually closed!",
        displayLastState: this.state.displayState,
        displayState: displayInstallError
      }, () => {
        console.log(this.state);
      });
    });
  }

  completeInstallation = () => {
    window.roDMUniversalInstaller.InstallComplete(this.state.installerId, (status, error, logTrace) => {
      console.log(error);
      if (!error) {
        this.setState({
          displayLastState: this.state.displayState,
          displayState: displayInstallationComplete
        }, () => {
          console.log("Installation complete -> %o", this.state);
        });
      } else {
        this.setState({
          status: "Error: " + status + " - Installer id: " + this.state.installerId,
          displayLastState: this.state.displayState,
          displayState: displayInstallError
        }, () => {
          console.log(this.state);
        });
      }            
    }, (error) => {
      // error
      if (error) {
        this.setState({
          status: error.toString(),
          displayLastState: this.state.displayState,
          displayState: displayInstallError
        });
      }
    })
  }

  render = () => {
    return (
      <React.Fragment>        
        <section className="seo_features_one sec_pad">
          <div className="container">                        
            {this.state.showLicAgree ?
              <InstallLicenseAgreement {...this.props} onClose={(agree) => this.closeAgreements(agree)} /> : null }
            {this.state.showTsAndCs ?
              <InstallTermsAndConditions {...this.props} onClose={(agree) => this.closeAgreements(agree)} /> : null }
            <div className="row flex-row-reverse">
              <div className="col-lg-5">
                <div className="seo_features_img">
                  <div className="round_circle"></div>
                  <div className="round_circle two"></div>
                  <img src={require('../img/seo/features_img.png')} alt="" />
                </div>
              </div>
              <div id="install-body" className="col-lg-7">
                <Fade bottom cascade>
                  <div className="seo_features_content">                    
                    <h2>
                      Getting started
                      {isDev() ? <span>&nbsp;[DEV]</span> : null}
                    </h2>
                    <p>Getting started with The Cyber Canary is quick and easy... once installed, you'll be shown how to test and see how The Cyber Canary works!</p>
                    {/* authenticate */}
                    <Element name="install-authenticate">
                      <div className="media seo_features_item">
                        <div className="icon two" style={{ paddingTop: "5px" }}><i className={`far fa-lock fa-2x`} /></div>
                        <div className="media-body">
                          <ComponentAuthentication {...this.props} onAuthenticated={this.onAuthenticated} isMobile={false} />
                        </div>
                      </div>
                    </Element>
                    {/* tc and cs */}
                    <Element name="install-ts-and-cs">
                      <div className="media seo_features_item">
                        <div className="icon" style={{ paddingTop: "5px" }}><i className={`far fa-tasks fa-2x`} /></div>
                        <div className="media-body">
                          <h3>Terms and conditions</h3>
                          {this.state.displayState <= displayTsAndCs ?
                            <React.Fragment>
                              <p>To continue, you need to agree to both the <NavLink to="#" onClick={(e) => this.setState({ showLicAgree: true})}>license agreement</NavLink> and <NavLink to="#" onClick={(e) => this.setState({ showTsAndCs: true})}>terms and conditions</NavLink>.</p>
                              {this.state.displayState === displayTsAndCs ?
                                <React.Fragment>                              
                                  <h4>
                                    &nbsp;<input type="checkbox" style={{ height: "17px", transform: "scale(1.5)" }} onClick={(e)  => this.agreeTsAndCs() } />
                                    &nbsp;
                                    I agree?&nbsp;<i className="fas fa-spinner fa-spin"></i>
                                  </h4>
                                </React.Fragment>
                              : null}
                            </React.Fragment>
                          :
                            <React.Fragment>
                              <h4><i className="far fa-check"></i>&nbsp;T's and C's complete!</h4>
                              <small>Need to <NavLink to="#" onClick={(e) => this.setState({ showLicAgree: true})}>read again</NavLink>?</small>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </Element>
                    <Element name="install-download">
                      {/* download */}
                      <div className="media seo_features_item">
                        <div className="icon two" style={{ paddingTop: "5px" }}><i className={`far fa-download fa-2x`} /></div>
                        <div className="media-body">
                          <h3>Download</h3>
                          {this.state.displayState <= displayDownload ?
                            <React.Fragment>
                              <p>Next, we need to download and run The Cyber Canary installer. The installer is used to validate and install The Cyber Canary on your machine. Click the download button to start.</p>
                              <small>If you have already downloaded the installer, <NavLink to="#" onClick={(e) => {e.preventDefault(); this.checkConnector()}}>click here</NavLink> to continue.</small>
                              {this.state.displayState < displayDownload ?
                                <button className="btn_hover app_btn" style={{width: "200px", textAlign: "center" }} disabled={true}>Download</button>
                              :
                                <a id="na-download-installer" href={getWebAppValue(Consts.WEB_APP_VALUE.DMUI)} className="btn_hover app_btn" style={{width: "200px", textAlign: "center" }} disabled={this.state.displayState < displayDownload} onClick={(e) => this.checkConnector()}>Download</a>
                              }
                            </React.Fragment>
                          :
                            <React.Fragment>
                              <h4><i className="far fa-check"></i>&nbsp;Download complete!</h4>
                              <small>Need to download again? <a href={getWebAppValue(Consts.WEB_APP_VALUE.DMUI)}>click here</a>. You can also download a zipped version from <a href={getWebAppValue(Consts.WEB_APP_VALUE.DMUI_ZIP)}>here</a>.</small>
                            </React.Fragment>
                          }                        
                        </div>
                      </div>
                    </Element>
                    <Element name="install-run-installer">
                      {/* run installer */}
                      <div className="media seo_features_item">
                      <div className="icon" style={{ paddingTop: "5px" }}><i className={`far fa-mouse fa-2x`} /></div>
                        <div className="media-body">
                          {this.state.displayState <= displayWaitingRun ?
                            <React.Fragment>
                              <h3>Run the installer</h3>                                                      
                              <p>Next we need to run the installer, this requires administrative access to run.</p>
                              {this.state.displayState === displayWaitingRun ?
                                <React.Fragment>
                                  <h4>Looking for the installer&nbsp;<i className="fas fa-spinner fa-spin"></i></h4>
                                </React.Fragment>
                              : null}
                            </React.Fragment>
                          :
                            <React.Fragment>
                              {this.state.displayState === displayInstalling ?
                                <React.Fragment>
                                  <h4>Installing, please wait...&nbsp;<i className="fas fa-spinner fa-spin"></i></h4>
                                  <small><strong>{this.state.progress}</strong></small><br/>
                                  <small>Doesn't seem to be running? <NavLink to="#" onClick={e => this.checkConnector()}>try again</NavLink>.</small>
                                </React.Fragment>
                              :
                                <React.Fragment>
                                  {this.state.displayState === displayInstallationComplete ?
                                    <React.Fragment>
                                      <h3>Installation</h3>                                      
                                      <h4><i className="far fa-check"></i>&nbsp;Installation complete!</h4>
                                      <small>You want to <NavLink to="#" onClick={e => this.setState({ displayState: displayDownload }) }>re-start</NavLink> the installation process?</small>
                                      <p>The installation is now complete, and The Cyber Canary is now monitoring your system for suspicious activity. You can now <a href="/testing">test your installation</a> to see how The Cyber Canary works!</p>
                                    </React.Fragment>
                                  :
                                    <React.Fragment>
                                      <p><span style={{ color: "red" }}><i className="fas fa-exclamation"></i>&nbsp;{this.state.status}</span></p>
                                      <small>You want to <NavLink to="#" onClick={e => this.authTryAgain() }>try again</NavLink>?</small>
                                    </React.Fragment>
                                  } 
                                </React.Fragment>   
                              }  
                            </React.Fragment>
                          }
                        </div>
                      </div>                      
                    </Element>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default InstallBodyPC;
