import React from 'react';

const HomeInstall = () => {
  return (
    <section id="home-install" className="security_action_area">
      <img className="p_absoulte img_bg" src={require("../img/home-security/sheld_bg.png")} alt="" />
      <div className="container">
        <div className="security_action_inner">
          <div className="media">
            <img src={require("../img/home-security/shield.png")} alt="" />
            <div className="media-body">
              <h3><span>Want to see The Cyber Canary in action?</span>Click to install a 30 day trial</h3>
              <small>&nbsp;Already installed? <a href="/testing">Run some tests</a> to see The Cyber Canary in action!</small>
            </div>
          </div>
          <a id="nav-install" href="/install" className="er_btn er_btn_two">Install</a>
        </div>
      </div>
    </section>
  )
}
export default HomeInstall;