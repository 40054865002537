import React, { Component } from "react";
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthenticationContextProvider } from "context/authentication";

/*------ pages-----*/
import { Home } from "./pages/home";
import { Install } from "./pages/install";
import { Testing } from "./pages/testing";
import { HowWorks } from "./pages/how-works";
import ScrollToTopRoute from "./ScrollToTopRoute";
import { PrivacyPolicy } from "./pages/privacy-policy";

class App extends Component {

  componentDidMount() {
    this.props.hideLoader();
  }

  render() {
    return (
      <AuthenticationContextProvider>
        <BrowserRouter>        
          <Switch>
            <ScrollToTopRoute exact={true} path={"/"} component={Home} />
            <ScrollToTopRoute path={"/install"} component={Install} />
            <ScrollToTopRoute path={"/install/:linkId"} component={Install} />
            <ScrollToTopRoute path={"/download"} component={Install} />
            <ScrollToTopRoute path={"/testing"} component={Testing} />
            <ScrollToTopRoute path={"/how-works"} component={HowWorks} />
            <ScrollToTopRoute path={"/privacy-policy"} component={PrivacyPolicy} />
            <ScrollToTopRoute component={Home} />
          </Switch>        
          </BrowserRouter>
      </AuthenticationContextProvider>
    );
  }
}

export default App;
