import React, { Component } from 'react';
import Navbar from '../components/navbar';
import HomeBanner from "../components/home-banner";
import HomeAbout from "../components/home-about";
import HomeFeatures from "../components/home-features";import HomeInstall from "../components/home-install";
import HomePurchase from "../components/home-purchase";
import HomeFooter from "../components/home-footer";
import HomeVideo from "../components/home-video";
import { Consts } from "config/consts";
import { DialogBox } from "../components/dialog-box";
import { StripePayment } from "../components/stripe-payment";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import Switch, { Case } from "react-switch-case";

const dialogNone = 0;
const dialogShow = 1;
const dialogStripe = 2;
const dialogVideo = 3;

const stripePromise = loadStripe(Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.API_KEY : Consts.STRIPE.DEV.API_KEY);

export class Home extends Component {
  constructor() {
    super();
    this.state = {
      dialogState: dialogNone,
      dialogType: 0,
      dialogTitle: "",
      dialogMessage: "",
      dialogOnClose: null,
      dialogStripePlanType: null
    }
  }

  displayDialog = (type, title, message, onClose) => {
    this.setState({
      dialogType: type,
      dialogTitle: title,
      dialogMessage: message,
      dialogOnClose: onClose,
      dialogState: dialogShow
    })
  }

  displayStripe = (planType) => {
    this.setState({
      dialogStripePlanType: planType,
      dialogOnClose: null,
      dialogState: dialogStripe
    });
  }

  displayVideo = () => {
    this.setState({
      dialogState: dialogVideo
    })
  }

  closeDialog = (accept) => {
    this.setState({
      dialogState: dialogNone
    });

    if (this.state.dialogOnClose)
      this.state.dialogOnClose();
  }

  closePayment = (paymentMade) => {
    this.setState({
      dialogState: dialogNone
    });
  }

  render() {
    return (
      <div id="home-main" className="body_wrapper">
        <Switch condition={this.state.dialogState}>
          <Case value={dialogShow}>
            <DialogBox {...this.props} type={this.state.dialogType} title={this.state.dialogTitle} message={this.state.dialogMessage} onClose={(accept) => this.closeDialog(accept)} />
          </Case>
          <Case value={dialogStripe}>
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({stripe, elements}) => (
                  <StripePayment {...this.props} stripe={stripe} elements={elements} displayDialog={this.displayDialog} planType={this.state.dialogStripePlanType} machineKey={this.machineKey} machineName={this.machineName} onClose={(paymentMade) => this.closePayment(paymentMade)} />
                )}
              </ElementsConsumer>
            </Elements>
          </Case>
          <Case value={dialogVideo}>
            <HomeVideo {...this.props} url="https://youtu.be/57kJQxxAvPg" onClose={(accept) => this.closeDialog(accept)} />
          </Case>
        </Switch>
        <Navbar mClass="menu_eight" nClass="w_menu" slogo="sticky_logo" hbtnClass="security_btn" onVideoClick={this.displayVideo} />
        <HomeBanner />
        <HomeAbout />
        <HomeFeatures />
        <HomeInstall />
        <HomePurchase {...this.props} displayDialog={this.displayDialog} displayStripe={this.displayStripe} />
        <HomeFooter />
      </div>
    )
  }
}