import React from 'react';
import { NavLink } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import { decodeRule } from "config/utilities"
import { Authentication } from "context/authentication";

const launchCommandLineHomeDocsFolder = 6;

class TestingBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualFile: "",
      allVirtualFiles: null
    }

    this.systemDrive = null;
    this.virtualFiles = [];
  }

  onAuthenticated = (authContext) => {
    // authenticated...
    this.getRules();
  }

  onNotAuthenticated = (authContext) => {
    console.log("No authentication!!!");
  }

  handleChange = (e) => {
    // update state
    this.setState({ [e.target.name]: e.target.value }, () => {
      // validate
      this.setState({ credentialsValidated: (this.state.email.indexOf("@") !== -1 && this.state.email.indexOf(".") !== -1 && this.state.password.length > 0) });
    });
  }

  waitOnEvent = (method, timeout) => {
    setTimeout(() => {
      method();
    }, timeout);
  }

  openCommandLine = () => {
    window.roFolderWall.LaunchApplication(launchCommandLineHomeDocsFolder, "", () => {
      console.log("Command line launched")
    }, (error) => {
      console.log(error.toString())
    })
  }

  getRules = () => {
    window.roFolderWall.GetAllMountPoints((mountPointList, stateValue) => {
      console.log("Got %s mount points, enumerating...", mountPointList.items.length)
      let drives = [];
      let key = 0;
      for (var i = 0; i < mountPointList.items.length; i++) {
        let s = mountPointList.items[i].split("|");
        let drive = {
          key: key++,
          id: "",
          rootPath: s[0],
          mountName: s[1],
          volumeLabel: s[2],
          mountType: s[3],
          rules: [],
          visible: s[3] === "VOLUME" ? true : false
        }

        // grab drive
        this.maxRulesPerDrive = 0;
        console.log("Getting mount point %s...", drive.rootPath);
        window.roFolderWall.GetMountPoint(drive.rootPath, (mountPoint, ignoreStateValue) => {
          console.log("Processing mount point %s for drive %s...", mountPoint.id.value, mountPoint.mountName.value);
          drive.id = mountPoint.id.value;
          drive.systemDrive = mountPoint.systemDrive.value;

          // get virtual files
          for (var j = 0; j < mountPoint.rules.value.items.length; j++) {
            drive.rules.push(mountPoint.rules.value.items[j]);
            let id = decodeRule(mountPoint.rules.value.items[j]).id;
            window.roFolderWall.GetVirtualFiles(id, (virtualFiles) => {
              virtualFiles.items.forEach((vf) => {
                this.virtualFiles.push(mountPoint.mountName.value.toLowerCase() + vf);
                // add default vf?
                if (!this.defaultVirtualFile && vf.indexOf(".txt") !== -1) {
                  this.setState({
                    virtualFile: vf.replace(/^.*[\\]/, '')
                  })
                }
              });
            });
          }

          // add drive
          drives.push(drive);
          if (drive.systemDrive) {
            this.systemDrive = drive;
          }

          // we done?
          if (drives.length === mountPointList.items.length) {
            // sort list
            drives.sort((a, b) => {
              if (a.mountName < b.mountName)
                return -1;
              if (a.mountName > b.mountName)
                return 1;
              return 0;
            });

            // done!
            this.stateValue = stateValue;
            this.setState({
              driveList: drives
            }, () => {
              console.log( "Cyber Canary drives have successfully loaded!");
            });
          }
        }, (error) => {
          console.log(error.message)
        });
      }
    }, (error) => {
      console.log(error.message)
    });
  }

  render = () => {
    return (
      <React.Fragment>
        <Authentication onAuthenticate={this.onAuthenticated} onNotAuthenticated={this.onNotAuthenticated} />
        <section className="seo_features_one sec_pad">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-5">
                <div className="seo_features_img">
                  <div className="round_circle"></div>
                  <div className="round_circle two"></div>
                  <img src={require('../img/seo/features_img_two.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-7">
                <Fade bottom cascade>
                  <div className="seo_features_content">
                    <h2>
                      Testing The Cyber Canary
                    </h2>
                    <p>Testing The Cyber Canary is important to ensure it is installed and working correctly, but is also a great way to see how it works!</p>
                    <div className="media seo_features_item">
                      <div className="icon" style={{ paddingTop: "5px" }}><i className="far fa-ghost fa-2x" /></div>
                      <div className="media-body">
                        <h3>Canary files</h3>
                        <p>First up, we are going to view some of the canary files. By default, these files are filtered from Windows Explorer (even if you have view hidden file enabled). The easiest way to view them is via the command line (if you have a third-party file manager, you can use this providing it will display hidden files). <NavLink to="#" onClick={(e) => this.openCommandLine()}>Click here to open a command line prompt</NavLink>, or open one via the Windows start menu.</p>
                        <br/>
                        <p>If you used the link, it should have opened in your documents folder (if not, type <code>cd /d %HOMEPATH%\Documents</code>). From here, type <code>dir /a:h</code>, (the /a:h forces hidden files to be displayed) and you will see the virtual files listed amongst your existing files.</p>
                        <br/>
                        {this.state.allVirtualFiles ?
                          <React.Fragment>
                            <p>
                              <NavLink to="#" onClick={(e) => this.setState({ allVirtualFiles: null })}>Hide canary files</NavLink>.
                            </p>
                            {this.state.allVirtualFiles.map((vf, i) => {
                              return(
                                <p key={i}>
                                  <small>
                                    {vf}
                                  </small>
                                </p>
                              )})
                            }
                          </React.Fragment>
                        :
                          <React.Fragment>
                            <p>
                              <NavLink to="#" onClick={(e) => this.setState({ allVirtualFiles: this.virtualFiles })}>Click here to see all canary files installed</NavLink>.
                            </p>
                          </React.Fragment>
                        }
                      </div>
                    </div>
                    <div className="media seo_features_item">
                      <div className="icon two" style={{ paddingTop: "5px" }}><i className="far fa-eye fa-2x" /></div>
                      <div className="media-body">
                        <h3>Lets reveal a canary file to play with</h3>
                        <p>Now, lets reveal a canary file that we can test with. Type <code>attrib -s -h {this.state.virtualFile}</code>, this will remove the hidden file attribute (don't worry, this will reset the next time The Cyber Canary starts).</p>
                      </div>
                    </div>
                    <div className="media seo_features_item">
                      <div className="icon" style={{ paddingTop: "5px" }}><i className="far fa-monitor-heart-rate fa-2x" /></div>
                      <div className="media-body">
                        <h3>Monitoring for changes</h3>
                        <p>Next, we are going to demonstrate what happens when an application or process, modifies a canary file. For this exercise, we are going to use Windows Notepad. Let open a canary file by typing <code>notepad {this.state.virtualFile}</code> and press enter.</p>
                        <p>Now modify by changing the text and then save (ctrl s), once The Cyber Canary detects the write operation, Notepad will be terminated, and you will receive a notification on-screen, and via email!</p>
                      </div>
                    </div>
                    <div className="media seo_features_item">
                      <div className="icon two" style={{ paddingTop: "5px" }}><i className="far fa-trash fa-2x" /></div>
                      <div className="media-body">
                        <h3>Deleting or rename files</h3>
                        <p>Finally, lets test what happens if a process tries to delete or rename a canary file. In this case, the process will be the command line application itself (cmd.exe). To delete, type <code>del {this.state.virtualFile}</code> and press enter. To rename, type <code>ren {this.state.virtualFile} newname.txt</code>. Either way you will see the command line interface terminate, in addition to receiving the prerequisite notifications.</p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default TestingBody;
