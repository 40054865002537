import React from "react";
import { Consts }from "config/consts.js"
import Notifications from 'react-notification-alert';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import uuid from "uuid";

import infoImg from "assets/img/info_32x32.png";
import confirmImg from "assets/img/confirm_32x32.png";
import errorImg from "assets/img/error_32x32.png";

export { Notifications }

export function isRelease() {
  return !isBeta() && !isDev();
}

export function isBeta() {
  return window.location.href.indexOf("https://beta.anvil-fs.com") === 0;
}

export function isDev() {
  return window.location.href.indexOf("http://localhost:3") === 0;
}

export function getWebAppValue(value) {
  if (isRelease()) {
    switch (value) {
      case Consts.WEB_APP_VALUE.NAME:
        return Consts.WEB_APP_RELEASE.NAME;
      case Consts.WEB_APP_VALUE.FULL_NAME:
        return Consts.WEB_APP_RELEASE.FULL_NAME;
      case Consts.WEB_APP_VALUE.INSTALL_ENABLED:
        return Consts.WEB_APP_RELEASE.INSTALL_ENABLED;
      case Consts.WEB_APP_VALUE.DMUI:
        return Consts.WEB_APP_RELEASE.DMUI;
      case Consts.WEB_APP_VALUE.DMUI_ZIP:
        return Consts.WEB_APP_RELEASE.DMUI_ZIP;
      case Consts.WEB_APP_VALUE.INSTALL_PACKAGE:
        return Consts.WEB_APP_RELEASE.INSTALL_PACKAGE;
      case Consts.WEB_APP_VALUE.URL:
        return Consts.WEB_APP_RELEASE.URL;
      default:
        console.log("The value: %s, does not exist", value);
        break;
    }
  }

  if (isBeta()) {
    switch (value) {
      case Consts.WEB_APP_VALUE.NAME:
        return Consts.WEB_APP_BETA.NAM
      case Consts.WEB_APP_VALUE.FULL_NAME:
        return Consts.WEB_APP_BETA.FULL_NAME;
      case Consts.WEB_APP_VALUE.INSTALL_ENABLED:
        return Consts.WEB_APP_BETA.INSTALL_ENABLED;
      case Consts.WEB_APP_VALUE.DMUI:
        return Consts.WEB_APP_BETA.DMUI;
      case Consts.WEB_APP_VALUE.DMUI_ZIP:
        return Consts.WEB_APP_BETA.DMUI_ZIP;
      case Consts.WEB_APP_VALUE.INSTALL_PACKAGE:
        return Consts.WEB_APP_BETA.INSTALL_PACKAGE;
      case Consts.WEB_APP_VALUE.URL:
        return Consts.WEB_APP_BETA.URL;
      default:
        console.log("The value: %s, does not exist", value);
        break;
    }
  }

  if (isDev()) {
    switch (value) {
      case Consts.WEB_APP_VALUE.NAME:
        return Consts.WEB_APP_DEV.NAME;
      case Consts.WEB_APP_VALUE.FULL_NAME:
        return Consts.WEB_APP_DEV.FULL_NAME;
      case Consts.WEB_APP_VALUE.INSTALL_ENABLED:
        return Consts.WEB_APP_DEV.INSTALL_ENABLED;
      case Consts.WEB_APP_VALUE.DMUI:
        return Consts.WEB_APP_DEV.DMUI;
      case Consts.WEB_APP_VALUE.DMUI_ZIP:
        return Consts.WEB_APP_DEV.DMUI_ZIP;
      case Consts.WEB_APP_VALUE.INSTALL_PACKAGE:
        return Consts.WEB_APP_DEV.INSTALL_PACKAGE;
      case Consts.WEB_APP_VALUE.URL:
        return Consts.WEB_APP_DEV.URL;
      default:
        console.log("The value: %s, does not exist", value);
        break;
    }
  }
}

export function displayNotification(ref, type, message, autoHide) {
  let options = {};
  if (type === Consts.MESSAGE_TYPE.OK) {
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_check",
      autoDismiss: autoHide === false ? 0 : 5
    };
  } else if (type === Consts.MESSAGE_TYPE.INFO) {
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_check",
      autoDismiss: autoHide === false ? 0 : 5
    };
  } else if (type === Consts.MESSAGE_TYPE.ERROR) {
    options = {
      place: "tr",
      message: message.replace("Error: ", ""),
      type: "danger",
      icon: "now-ui-icons ui-1_simple-remove",
      autoDismiss: autoHide === false ? 0 : 5
    };
  }

  if (ref)
    ref.notificationAlert(options, message, autoHide);
}

export function displayNotificationSuccess(ref, message, autoHide) {
  displayNotification(ref, Consts.MESSAGE_TYPE.OK, message, autoHide);
}

export function displayNotificationError(ref, message, autoHide) {
  displayNotification(ref, Consts.MESSAGE_TYPE.ERROR, message, autoHide);
}

export function displayNotificationInfo(ref, message, autoHide) {
  displayNotification(ref, Consts.MESSAGE_TYPE.INFO, message, autoHide);
}

export class DialogAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variant: "info",
      title: "",
      message: "",
      onClose: null,
      onCancel: null,
      open: false
    }
  }

  handleCancel = () => {
    this.setState({
      open: false
    })
    if (this.state.onCancel)
      this.state.onCancel();
  }

  handleAccept = () => {
    this.setState({
      open: false
    })
    if (this.state.onClose)
      this.state.onClose();
  }

  render = () => {
    if (this.state.type === Consts.MESSAGE_TYPE.INFO) {
      return (
        <Modal isOpen={this.state.open} centered={true} backdrop={true}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <img src={infoImg} alt="ANVIL" style={{ marginRight: "10px", marginTop: "-3px" }} />{this.state.title}
            </h5>
          </div>
          <ModalBody>
            <p>{this.state.message}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={(e) => this.handleAccept()}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    if (this.state.type === Consts.MESSAGE_TYPE.CONFIRM) {
      return (
        <Modal isOpen={this.state.open} centered={true} backdrop={true}>
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              <img src={confirmImg} alt="ANVIL" style={{ marginRight: "10px", marginTop: "-3px" }} />{this.state.title}
            </h4>
          </div>
          <ModalBody>
            <p>{this.state.message}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={(e) => this.handleCancel()}>
              No
            </Button>
            <Button color="info" onClick={(e) => this.handleAccept()}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    if (this.state.type === Consts.MESSAGE_TYPE.ERROR) {
      return (
        <Modal isOpen={this.state.open} centered={true} backdrop={true}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <img src={errorImg} alt="ANVIL" style={{ marginRight: "10px", marginTop: "-3px" }} />{this.state.title}
            </h5>
          </div>
          <ModalBody>
            <p>{this.state.message}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={(e) => this.handleAccept()}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    return null;
  }
}

export function displayDialog(ref, type, title, message, onClose, onCancel) {
  ref.setState({
    type: type,
    title: title,
    message: message,
    onClose: onClose,
    onCancel: onCancel,
    open: true
  })
}

export function displayDialogInfo(ref, title, message, onOk) {
  displayDialog(ref, Consts.MESSAGE_TYPE.INFO, title, message, onOk);
}

export function displayDialogConfirm(ref, title, message, onConfirm, onCancel) {
  displayDialog(ref, Consts.MESSAGE_TYPE.CONFIRM, title, message, onConfirm, onCancel);
}

export function displayDialogError(ref, title, message, onOk) {
  displayDialog(ref, Consts.MESSAGE_TYPE.ERROR, title, message, onOk);
}

export function displayDialogHide(ref) {
  ref.dialogAlert.setState({ open: false });
}

export function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function decodeRule(rule) {
  if (!rule) {
    rule = Consts.RULES.DEFAULT_RULE;  // set default
  }

  let r = rule.split("|");

  // check id
  if (r.length >= 6) {
    if (r[5] === '{0}')
      r[5] = createGuid();
  }

  // check metadata
  if (r.length >= 7) {
    try {
      JSON.parse(r[6]);
    } catch(err) {
      // failed, issue with json
      console.log("Metadata Json format error, the following string failed");
      console.log(r[6]);
      console.log(err);
      r[6] = "{\"ERROR\": true, \"DESC\": \"Json parse failed with error " + err + "\"}";
    }
  }

  r = {
    index: parseInt(r[0], 10),
    name: r[1],
    access: r[2],
    processes: r[3] === "[]" ? [] : r[3].replace("[", "").replace("]", "").split(","),
    folders: r[4] === "[]" ? [] : r[4].replace("[", "").replace("]", "").split(","),
    id: r.length >= 6 ? r[5] : null,
    metadata: r.length >= 7 ? JSON.parse(r[6]) : null,
    processesStr: r[3],
    foldersStr: r[4]
  }

  // scan for <%comma%>
  let up = [];
  r.processes.forEach((p) => {
    up.push(p.replace(/<%COMMA%>/g, ","));
  });
  r.processes = up;

  return r;
}

export function decodeAccess(access, matchOn) {
  if (matchOn === Consts.RULE_ACCESS.WRITE_ALLOW && access.indexOf("W+") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.WRITE_DENIED && access.indexOf("W-") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.WRITE_DENIED_SILENT && access.indexOf("W~") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.WRITE_REQUESTED && access.indexOf("W?") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.WRITE_IMMUTABLE && access.indexOf("W1") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.WRITE_LEARN && access.indexOf("W>") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.WRITE_ENCRYPTED && access.indexOf("W*") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.READ_ALLOW && access.indexOf("R+") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.READ_DENIED && access.indexOf("R-") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.READ_DECRYPTED && access.indexOf("R*") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.LIST_ALLOW && access.indexOf("L+") !== -1)
    return true;
  if (matchOn === Consts.RULE_ACCESS.LIST_DENIED && access.indexOf("L-") !== -1)
    return true;
  return false;
}

export function encodeRule(ruleObj) {
  // processes
  let p = "";
  ruleObj.processes.forEach((process) =>{
    p = p.length === 0 ? p = (process.indexOf("<") === 0 ? process.replace(/,/g, "<%COMMA%>") : process.toLowerCase()) : p = p + "," + (process.indexOf("<") === 0 ? process : process.toLowerCase());
  })

  // folders
  let f = "";
  ruleObj.folders.forEach((folder) => {
    f = f.length === 0 ? f = folder.toLowerCase() : f = f + "," + folder.toLowerCase();
  })

  // id
  let id = ruleObj.id ? ruleObj.id : createGuid();

  // metadata
  let o = ruleObj.metadata ? JSON.stringify(ruleObj.metadata) : "";

  return ruleObj.index + "|" + ruleObj.name + "|" + ruleObj.access + "|[" + p + "]|[" + f + "]|" + id + "|" + o;
}

export function encodeRuleArray(ruleArray) {
  let a = "";
  ruleArray.forEach((arr) => {
    a = a.length === 0 ? a = arr : a = a + "," + arr;
  })

  return "[" + a + "]";
}

export function rulesListInsert(ruleList, ruleObject) {
  // insert at front
  let nl = [];
  ruleObject.forEach((r) => {
    nl.push(decodeRule(r));
  });

  ruleList.forEach((r) => {
    nl.push(decodeRule(r));
  });

  // now check index value
  let i = 1;
  nl.forEach((r) => {
    if (r.index < i) {
      r.index = i;
    }
    i++;
  });

  // create returning result
  let rl = [];
  nl.forEach((r) => {
      rl.push(encodeRule(r));
  });

  return rl;
}

export function rulesDeleteByGroupId(ruleList, groupId) {
  if (!groupId)
    return;

  let nl = []
  ruleList.forEach((r) => {
    let dr = decodeRule(r);
    if (dr.metadata.GROUP_ID !== groupId)
      nl.push(r)
  })

  return nl;
}

export function arrayToFormattedStr(arr, limit, replaceArray) {
  arr = arr.slice(0);  // clone first

  // sanity check
  limit = parseInt(limit);  // make sure int

  if (limit > arr.length)
    limit = arr.length;

  if (limit && limit < 999 && arr.length > limit) {
    limit = limit + 1;
    arr.length = limit;
    arr[limit - 1] = "more";
  }

  if (arr.length === 0) {
    return "";
  }

  if (arr.length === 1) {
    if (replaceArray) {
      for(let i = 0; i < replaceArray.length; i = i + 2) {
        arr[0] = arr[0].replace(replaceArray[i], replaceArray[i + 1]);
      }
    }
    return arr[0];
  }

  let r = "";
  for (var i = 0; i < arr.length; i++) {
    if (i < arr.length - 2) {
      r = r + "\"" + arr[i] + "\", ";
    } else if (i === arr.length - 2) {
      r = r + "\"" + arr[i] + "\" and ";
    } else {
      if (arr[i] !== "more") {
        r = r + "\"" + arr[i] + "\"";
      } else {
        r = r + arr[i];
      }
    }
  }

  if (replaceArray) {
    for (let i = 0; i < replaceArray.length; i = i + 2) {
      r = r.replace(replaceArray[i], replaceArray[i + 1]);
    }
  }

  return r;
}

export function pushDedup(intoArray, item) {
  var checkArray = intoArray;
  for(let i = 0; i < intoArray.length; i++) {
    if (intoArray[i].toLowerCase() === item.toLowerCase())
      return checkArray;
  }

  // if we get here no dup, add item
  intoArray.push(item);
  return intoArray;
}

export function createGuid() {
  return "{" + uuid.v4() + "}";
}

export function validateFolder(folder, retainDriveLetter, includeTrailingSeparator) {
  folder = folder.replace("\\\\", "\\");

  if (!retainDriveLetter) {
    if (folder.indexOf(":") !== -1) {
      folder = folder.substring(2);
    }
  }

  if (!includeTrailingSeparator) {
    if (folder.endsWith("\\")) {
      folder = folder.substring(0, folder.length - 1);
    }
  } else {
    if (!folder.endsWith("\\")) {
      folder = folder + "\\";
    }
  }
  return folder
}

export function createRuleSet(baseRuleObj, rules) {
  let result = {
    valid: true,
    baseRuleObj: baseRuleObj,
    name: null,
    description: null,
    nonGroupRules: 0,
    groupId: null,
    ruleObjs: []
  }
  result.ruleObjs.push(baseRuleObj);

  // make sure base rule is valid
  if (!baseRuleObj.id || !baseRuleObj.metadata || !baseRuleObj.metadata.GROUP_ID) {
    console.log("Cannot create rule set for base rule: %s", baseRuleObj.id);
    result.valid = false;
    return result;
  }

  // set group id
  result.groupId = baseRuleObj.metadata.GROUP_ID;

  // set and and description
  result.name = baseRuleObj.name;
  result.description = baseRuleObj.metadata ? baseRuleObj.metadata.DESC : "";

  let lastRuleObj = null;
  // find last group rule
  rules.forEach((rule) => {
    let ruleObj = decodeRule(rule);
    if (ruleObj.metadata && ruleObj.metadata.GROUP_ID === baseRuleObj.metadata.GROUP_ID) {
      lastRuleObj = ruleObj;
    }
  })

  if (lastRuleObj.id === baseRuleObj)
    return result;  // only one entry

  // check for more rules
  let hasBaseRule = false;
  for (let i = 0; i < rules.length; i++) {
    let ruleObj = decodeRule(rules[i]);
    if ((!ruleObj.metadata && hasBaseRule) || (ruleObj.metadata && ruleObj.metadata.GROUP_ID === baseRuleObj.metadata.GROUP_ID)) {
      // add if not base rule
      if (ruleObj.id !== baseRuleObj.id) {
        result.ruleObjs.push(ruleObj);
      } else {
        hasBaseRule = true;
      }

      // check for last rule and bail
      if (ruleObj.id === lastRuleObj.id) {
        // done
        return result;
      }
    } else {
      if (hasBaseRule) {
        result.nonGroupRules++;
      }
    }
  }

  // if we get here, no last rule found... odd
  console.log("Cannot create rule set for base rule: %s", baseRuleObj.id);
  result.valid = false;
  return result;
}

export function mergeRuleObj(ruleObj, rules) {
  let result = [];
  rules.forEach((rule) => {
    let ro = decodeRule(rule);
    if (ro.id === ruleObj.id) {
      // found
      result.push(encodeRule(ruleObj));
    } else {
      result.push(rule);
    }
  });

  return result;
}

export function mergeRuleSet(ruleSetObj, rules) {
  // first create a list of the original rules objs
  let ruleObjs = [];
  rules.forEach((rule) => {
    ruleObjs.push(decodeRule(rule));
  });

  // next merge rule set objs into rule objs
  let result = [];
  ruleObjs.forEach((ruleObj, i) => {
    ruleSetObj.ruleObjs.forEach((rso) => {
      if (ruleObj.id === rso.id) {
        ruleObjs[i].access = rso.access;
        ruleObjs[i].processes = rso.processes.slice(0);
      }
    })

    // do name and description
    if (ruleObj.id === ruleSetObj.baseRuleObj.id) {
      ruleObj.name = ruleSetObj.name;
      ruleObj.metadata.DESC = ruleSetObj.description;
    }

    // push into rules
    result.push(encodeRule(ruleObj));
  });

  return result;
}

export function getRuleById(id, rules) {
  for (let i = 0; i < rules.length; i++) {
    let ro = decodeRule(rules[i]);
    if (ro.id === id) {
      return rules[i];
    }
  }
  return null;
}