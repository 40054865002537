import React, { Component } from 'react';
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";

class HomeVideo extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <Modal open={true} onClose={this.props.onClose} styles={{
          modal: {
            maxWidth: "unset",
            width: "95%",
            padding: "unset"
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)"
          },
          closeButton: {
            background: "#c0c0c0"
          }
        }}center>
        <ReactPlayer url={this.props.url} playing={true} width="100%" height="calc(100vh - 100px)" />
      </Modal>
    );
  }
}
export default HomeVideo;