import React from "react";
import firebase from "firebase/app";
import { Firebase, Firestore } from "config/firebase";
import { Consts } from "config/consts";
import { userModel } from "context/user";
import { createGuid } from "config/utilities";

export const AuthenticationContext = React.createContext();

export const defaultAuthState = {
  initialized: false,
  authenticated: false,
  verified: false,
  licenseUpgradeAction: null,
  uid: 0,
  email: "",
  displayName: null,
  avatar: "/images/default-user.png",
  roles: Consts.ROLES.USER,
  id: createGuid()
}

export class AuthenticationContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultAuthState;
    this.explicitAuth = false;
    this.stateChangeCallback = [];
    this.authenticationToken = null;
  }

  componentDidMount = () => {
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (this.explicitAuth)
          return; // explicit authentication... ignore this callback
        console.log("Authentication change state fired, user: %s logging in!", user.email);
      } else {
        console.log("Authentication change state fired logging out!");
      }

      this.setAuthenticatedUser(user).then((user) => {
        // callback?
        for (let i = 0; i < this.stateChangeCallback.length; i++)
          this.stateChangeCallback[i](user, this);
      }).catch((error) => {
        // callback?
        for (let i = 0; i < this.stateChangeCallback.length; i++)
          this.stateChangeCallback[i](null, null);
      });
    })
  }

  registerUser = (email, password, userObject) => {
    return new Promise((resolve, reject) => {
      // make sure flagged as explicit auth
      this.setState({
        explicitAuth: true
      }, () => {
        Firebase.auth().createUserWithEmailAndPassword(email, password).then((userCredential) => {
          // send verification email
          userCredential.user.sendEmailVerification();

          // signed in... create base user object
          let d = new Date();
          let newUser = userObject ? userObject : userModel;
          newUser.authenticated_user = true;
          newUser.contact = { ...newUser.contact, email: email };
          newUser.timestamp = { ...newUser.timestamp, join: d.getTime() }

          // create initial entry - this is all a bit clunky, but because we are allowing user creation before auth registration, we need to merge this data.
          Firestore.collection("users").doc(userCredential.user.uid).set(newUser).then(() => {
            // we need to reload
            this.setAuthenticatedUser(userCredential.user).then((user) => {
              resolve(user);
            }).catch((error) => {
              reject(error);
            })
          });
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      // make sure flagged as explict auth
      this.setState({
        explicitAuth: true
      }, () => {
        Firebase.auth().signInWithEmailAndPassword(email, password).then((userCredential) => {
          // signed in
          this.setAuthenticatedUser(userCredential.user).then((user) => {
            resolve(userCredential.user);
          }).catch((error) => {
            reject(error);
          })
        }).catch((error) => {
          // error
          reject(error);
        });
      });
    });
  }

  logoutUser = () => {
    return new Promise((resolve, reject) => {
      Firebase.auth().signOut().then(() => {
        // signed out
        this.setAuthenticatedUser(null).then((user) => {
          resolve();
        });
      }).catch((error) => {
        // error
        reject(error);
      });
    })
  }

  authenticateOAuth = (oauthProviderType) => {
    return new Promise((resolve, reject) => {
      var provider = null;
      if (oauthProviderType === Consts.OAUTH_PROVIDERS.GOOGLE) {
        provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
      } else if (oauthProviderType === Consts.OAUTH_PROVIDERS.FACEBOOK) {
        provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('email');
      } else if (oauthProviderType === Consts.OAUTH_PROVIDERS.TWITTER) {
        //provider = new firebase.auth.TwitterAuthProvider();
      } else if (oauthProviderType === Consts.OAUTH_PROVIDERS.GITHUB) {
        //provider = new firebase.auth.GithubAuthProvider();
      }

      // do we have a valid provider
      if (!provider) {
        reject("Provider not supported.");
      }

      // popups not working with edge
      this.setState({
        explicitAuth: true
      }, () => {
        if (/Edge\/\d./i.test(navigator.userAgent)) {
          // this is microsoft edge
          reject("Microsoft Edge (non chromium version) is not supported.");
        } else {
          Firebase.auth().signInWithPopup(provider).then((result) => {
            // determine if new
            if (result.additionalUserInfo.isNewUser) {
              // signed in... create base user object
              let d = new Date();
              let newUser = userModel;

              // try and get name and other dets
              newUser.name_first = result.user.displayName.split(' ').slice(0, -1).join(' ');
              newUser.name_last = result.user.displayName.split(' ').slice(-1).join(' ');
              newUser.authenticated_user = true;
              newUser.contact = { ...newUser.contact, email: result.user.email };
              newUser.timestamp = { ...newUser.timestamp, join: d.getTime() }

              // create initial entry - this is all a bit clunky, but because we are allowing user creation before auth registration, we need to merge this data.
              Firestore.collection("users").doc(result.user.uid).set(newUser).then(() => {
                // we need to reload
                this.setAuthenticatedUser(result.user).then((user) => {
                  resolve(user);
                }).catch((error) => {
                  reject(error);
                })
              });
            } else {
              this.setAuthenticatedUser(result.user).then((user) => {
                resolve(result.user)
              }).catch((error) => {
                reject(error);
              })
            }
          }).catch((error) => {
            reject(error);
          });
        }
      });
    });
  };

  setAuthenticatedUser = (user) => {
    return new Promise((resolve, reject) => {
      let promises = [];
      if (user) {
        // assign
        this.setState({
          initialized: true,
          authenticated: true,
          //verified: user.emailVerified,
          verified: true,  // until we resolve email issue
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          avatar: user.photoURL,
          roles: -1,
          userAtAuth: null
        });

        // assign token with uid (this is a temp hack, the service only supports checking the uid... see below commented code on assigning the correct token)
        window.roFolderWall.AssignAuthenticationToken(user.uid);

        // get authentication token -- // for the moment we are simply using
        // let tokenFunct = FirebaseFuncts.httpsCallable("getAuthenticationToken");
        // promises.push(tokenFunct({
        //     uid: user.uid
        //   }).then((result) => {
        //     this.authenticationToken = result.data.token;
        //     window.roFolderWall.AssignAuthenticationToken(this.authenticationToken);
        //     console.log("Using session token: %s", result.data.token);
        //   }).catch((error) => {
        //     console.log(error.message);
        //   }));

        // grab other user based values
        console.log("Getting user details for: %s", user.uid);
        promises.push(Firestore.collection("users").doc(this.state.uid).get().then((doc) => {
          if (doc.exists) {
            this.setState({
              displayName: doc.data().name_first,
              avatar: doc.data().avatar,
              roles: doc.data().roles,
              userAtAuth: doc.data()  // important - this is an immutable object
            }, () => {
              resolve(user);
            });
          } else {
            // doc.data() will be undefined in this case
            this.setState({ displayName: "The user was not found" }, () => {
              resolve(user);
            });
          }
        }).catch(function (error) {
          // error
          this.setState({ displayName: "Error getting name" });
          reject(error);
        }));

        // write last login
        var d = new Date();
        Firestore.collection("users").doc(this.state.uid).set({
          contact: { email_validated: this.state.verified },
          timestamp: { last_login: d.getTime() }
        }, { merge: true });

        // wait on all promises
        Promise.all(promises).then((result) => {
          // all good
          console.log("Set authenticated user complete for user: %s", user.uid);
          resolve(user);
        }).catch((error) => {
          // error
          reject(error);
        });
      } else {
        // reset
        this.setState({
          initialized: true,
          authenticated: false,
          uid: 0,
          email: "",
          displayName: "",
          avatar: "/images/default-user.png",
          roles: -1,
          userAtAuth: null
        });

        // no promise... return null (not great architecture???)
        resolve(user);
      }
    });
  }

  monitorStateChange = (callback) => {
    this.stateChangeCallback.push(callback);
  }

  sendPasswordResetRequest = (email) => {
    return new Promise((resolve, reject) => {
      console.log("Requesting password reset for: %s", email);
      Firebase.auth().sendPasswordResetEmail(email).then(() => {
        resolve();
      }).catch(function(error) {
        // error
        reject(error);
      });
    });
  }

  sendAccountVerificationEmail = () => {
    return new Promise((resolve, reject) => {
      console.log("Sending account verificaion...");
      Firebase.auth().currentUser.sendEmailVerification().then(() => {
        resolve();
      }).catch(function (error) {
        // error
        reject(error);
      });
    });
  }

  checkAccountVerified = () => {
    return new Promise((resolve, reject) => {
      console.log("Checking account verification...");
      if (Firebase.auth().currentUser) {
        Firebase.auth().currentUser.reload().then(() => {
          let user = Firebase.auth().currentUser;
          if (user.emailVerified) {
            this.setAuthenticatedUser(user).then((user) => {
              console.log("Account verified!");
              resolve(true);
            }).catch((error) => {
              console.log(error);
              resolve(false);
            });
          } else {
            console.log("Account not verified!");
            resolve(false);
          }
        }).catch(function (error) {
          // error
          reject(error);
        });
      } else {
        console.log("User inactive, account not verified!");
        resolve(false);
      }
    });
  }

  setLicenseUpgradeAction = (licenseUpgradeAction) => {
    this.setState({
      licenseUpgradeAction: licenseUpgradeAction
    })
  }

  render() {
    return (
      <AuthenticationContext.Provider value={{
        state: this.state,
        registerUser: (email, password, userObject) => this.registerUser(email, password, userObject),
        authenticateOAuth: (oauthProviderType) => this.authenticateOAuth(oauthProviderType),
        loginUser: (email, password) => this.loginUser(email, password),
        logoutUser: () => this.logoutUser(),
        monitorStateChange: (component, callback) => this.monitorStateChange(component, callback),
        sendPasswordResetRequest: (email) => this.sendPasswordResetRequest(email),
        sendAccountVerificationEmail: () => this.sendAccountVerificationEmail(),
        checkAccountVerified: () => this.checkAccountVerified(),
        setLicenseUpgradeAction: (licenseUpgradeOnClick) => this.setLicenseUpgradeAction(licenseUpgradeOnClick)
      }}>
        {this.props.children}
      </AuthenticationContext.Provider>
    )
  }
}

export class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.initd = false;
  }

  init = (authContext) => {
    if (!this.initd) {
      // if state initialed, then authenticate
      if (authContext.state.initialized)
        this.authenticate(authContext);

      // register for updates
      authContext.monitorStateChange(this.authStateChanged.bind(this));
    }
    this.initd = true;
  }

  authStateChanged = (user, authContext) => {
    // call authenticated
    this.authenticate(authContext)
  }

  authenticate = (authContext) => {
    let r = false;

    // authenticated?
    if (authContext.state.authenticated)
      r = true;

    if (r) {
      if (this.props.onAuthenticate) {
        setTimeout(() => {
          this.props.onAuthenticate(authContext);  // decouple from parent render method (not sure this is the best way to do this???)
        }, 1);
      }
    }

    if (!r) {
      if (this.props.onNotAuthenticated) {
        setTimeout(() => {
          this.props.onNotAuthenticated(authContext);  // decouple from parent render method (not sure this is the best way to do this???)
        }, 1);
      }
    }

    return r;
  }

  render() {
    return (
      <AuthenticationContext.Consumer>
      { authContext => this.init(authContext) }
      </AuthenticationContext.Consumer>
    )
  }
}