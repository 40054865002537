import React, { Component } from "react";
import NavbarSimple from "../components/navbar-simple"
import InstallBodyPC from "../components/install-body-pc";
import InstallBodyMobile from "../components/install-body-mobile";
import HomeFooter from "../components/home-footer";
import { BrowserView, MobileView } from "react-device-detect";
import { FirebaseFuncts } from "config/firebase";
import { Authentication } from "context/authentication";
import Loadable from "react-loading-overlay";
import { DialogBox } from "../components/dialog-box";
import { Consts } from "config/consts";

export class Install extends Component {
  constructor(props) {
    super();
    let lid = props.location.pathname.replace(props.match.path, "").replace("/", "");
    this.state = {
      linkId: lid,
      loader: false
    }
    this.authContext = null;
  }

  onAuthenticate = (authContext) => {
    // assign
    this.authContext = authContext;

    // if we have a link id, then attempt to log us in
    if (this.state.linkId === "logout") {
      authContext.logoutUser();
      return;
    }
  }

  onNotAuthenticated = (authContext) => {
    // // ignore logout
    if (this.state.linkId === "logout") {
      return;
    }

    // we have a link?
    if (this.state.linkId) {
      console.log("Attempting to login using link %s", this.state.linkId);
      // start loader and get details (use timer to decouple)
      this.setState({
        loader: true
      }, () => {
        // get details
        let getLinkAccess = FirebaseFuncts.httpsCallable("getLinkAccess");
        getLinkAccess({
            key: this.state.linkId
          }).then((result) => {
            // all good
            if (result.data.method === "EMAIL") {
              // login user via email
              authContext.loginUser(result.data.email, result.data.password).then((user) => {
                // successful
                console.log("Login successful, please wait...");
                this.setState({
                  loader: false
                })
              }).catch((error) => {
                // error
                console.log(error.toString());
                this.setState({ error: error.toString() })
              });
            } else {
              // login via oauth
              const oauth = result.data.method;
              authContext.authenticateOAuth(oauth).then((user) => {
                // successful
                console.log("Register/login successful, please wait...");
                this.setState({
                  loader: false
                })
              }).catch((error) => {
                // error
                console.log(error.toString());
                this.setState({ error: error.toString() })
              });
            }
          }).catch((error) => {
            console.log(error.toString());
            this.setState({ error: error.toString() })
          });
      });
    }
  }

  closeDialog = (accept) => {
    this.setState({
      error: null,
      loader: false
    });
  }

  render() {
    return (
      <React.Fragment>
        <Authentication onAuthenticate={this.onAuthenticate} onNotAuthenticated={this.onNotAuthenticated} />
        <div className="body_wrapper">
          <NavbarSimple />
          {this.state.error ?
            <DialogBox {...this.props} type={Consts.MESSAGE_TYPE.ERROR} title="Install error" message={this.state.error} onClose={(accept) => this.closeDialog(accept)} /> : null }
          <BrowserView>
            <Loadable active={this.state.loader} spinner text="Checking installation link...">
              <InstallBodyPC {...this.props} linkId={this.state.linkId} />
            </Loadable>
          </BrowserView>
          <MobileView>
            <Loadable active={this.state.loader} spinner text="Checking installation link...">
              <InstallBodyMobile {...this.props} linkId={this.state.linkId} />
            </Loadable>
          </MobileView>
          <HomeFooter />
        </div>
      </React.Fragment>
    )
  }
}