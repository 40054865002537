import React from "react";
import Switch, { Case, Default } from "react-switch-case";
import { AuthenticationContext } from "context/authentication";
import { Authentication } from "context/authentication";
import { userModel } from "context/user";
import { Consts } from "config/consts";
import { NavLink } from "react-router-dom";
import { FirebaseFuncts } from "config/firebase";

const displayAuthAll = 0;
const displayAuthNewEmail = 1;
const displayAuthLoginEmail = 2;
const displayAuthNoRegister = 3;
const displayAuthError = 4;
const displayAuthenticated = 5;

var scroll = require('react-scroll');
var Element = scroll.Element;
var scroller = scroll.scroller;
var lastScrollSection = null;

export class ComponentAuthentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayState: displayAuthAll,
      busy: false,
      googleBusy: false,
      facebookBusy: false,
      credentialsValidated: false,
      email: "",
      password: "",
    }
  }

  scrollTo = (section) => {
    if (section === lastScrollSection)
      return;

    let options = {
      smooth: true,
      offset: -100,
      duration: 500,
      isDynamic: true
    };

    // only allowing 1 scroll atm
    // if (section !== "install-run-installer")
    //   return;
    //console.log("Scrolling to %s", section);
    scroller.scrollTo(section, options);
    lastScrollSection = section;
  }

  handleChange = (e) => {
    // update state
    this.setState({ [e.target.name]: e.target.value }, () => {
      // validate
      if (this.props.isMobile) {
        this.setState({ credentialsValidated: (this.state.email.indexOf("@") !== -1 && this.state.email.indexOf(".") !== -1) });
      } else {
        this.setState({ credentialsValidated: (this.state.email.indexOf("@") !== -1 && this.state.email.indexOf(".") !== -1 && this.state.password.length > 0) });
      }
    });
  }

  onAuthenticate = (authContext) => {
    this.props.onAuthenticated(authContext.state);
    this.setState({
      displayState: displayAuthenticated,
      busy: false,
      googleBusy: false,
      facebookBusy: false
    })
  }

  onNotAuthenticated = (authContext) => {
    this.props.onAuthenticated(null);
    this.setState({
      displayState: displayAuthAll,
      busy: false,
      googleBusy: false,
      facebookBusy: false
    })
  }

  logout = (e, authContext) => {
    authContext.logoutUser();
  }

  authTryAgain = () => {
    this.setState({
      displayState: this.state.displayLastState
    })
  }

  authCreateUserWithEmailAndPassword = (e, authContext) => {
    e.preventDefault();

    if (!this.state.email) {
      this.setState({
        status: "You must enter an email address.",
        displayLastState: this.state.displayState,
        displayState: displayAuthError,
        busy: false
      })
      return;
    }

    if (!this.state.password) {
      this.setState({
        status: "You must enter a password.",
        displayLastState: this.state.displayState,
        displayState: displayAuthError,
        busy: false
      })
      return;
    }

    // flag busy
    this.setState({
      busy: true
    }, () => {
      // register
      authContext.registerUser(this.state.email, this.state.password, userModel).then((user) => {
        console.log("User successfully created and signed in with email: %s", this.state.email);
      }).catch((error) => {
        console.log(error.toString());
        this.setState({
          status: error.toString(),
          displayLastState: this.state.displayState,
          displayState: displayAuthError,
          busy: false
        })
      })
    })
  }

  authCreateUserWithEmailAndLink = (e, authContext) => {
    e.preventDefault();

    if (!this.state.email) {
      this.setState({
        status: "You must enter an email address.",
        displayLastState: this.state.displayState,
        displayState: displayAuthError,
        busy: false
      })
      return;
    }

    // flag busy
    this.setState({
      busy: true }
    , () => {
      // gen link
      let generateLinkAccess = FirebaseFuncts.httpsCallable("generateLinkAccess");
      generateLinkAccess({
        method: "EMAIL",
        email: this.state.email
      }).then((result) => {
        // register user
        const key = result.data.key;
        const password = result.data.password
        authContext.registerUser(this.state.email, password, userModel).then((user) => {
          console.log("User successfully created and signed in with email: %s", this.state.email);
          console.log(user.uid);
          // now finalize link
          let finalizeLinkAccess = FirebaseFuncts.httpsCallable("finalizeLinkAccess");
          finalizeLinkAccess({
              key: key,
              uid: user.uid,
              email: user.email
            }).then((result) => {
              // all good
            }).catch((error) => {
              console.log(error.toString());
              this.setState({
                status: error.toString(),
                displayLastState: this.state.displayState,
                displayState: displayAuthError,
                busy: false
              })
            });
        }).catch((error) => {
          console.log(error.toString());
          this.setState({
            status: error.toString(),
            displayLastState: this.state.displayState,
            displayState: displayAuthError,
            busy: false
          })
        })
      }).catch((error) => {
        console.log(error.toString());
        this.setState({
          status: error.toString(),
          displayLastState: this.state.displayState,
          displayState: displayAuthError,
          busy: false
        })
      });
    });
  }

  authWithEmailAndPassword = (e, authContext) => {
    e.preventDefault();
    // flag busy
    this.setState({
      busy: true
    }, () => {
      // login user
      authContext.loginUser(this.state.email, this.state.password).then((user) => {
        // successful
        console.log("Login successful, please wait...");
      }).catch((error) => {
        // error
        console.log(error.toString());
        this.setState({
          status: error.toString(),
          displayLastState: this.state.displayState,
          displayState: displayAuthError,
          busy: false
        })
      });
    });
  }

  authWithOAuth = (oauth, e, authContext) => {
    // if mobile, use link
    if (this.props.isMobile)
       return this.authWithOAuthWithLink(oauth, e, authContext);

    e.preventDefault();
    // flag busy
    this.setState({
      busy: true,
      googleBusy: oauth === Consts.OAUTH_PROVIDERS.GOOGLE ? true : false,
      facebookBusy: oauth === Consts.OAUTH_PROVIDERS.FACEBOOK ? true : false
    }, () => {
      // auth with oauth
      authContext.authenticateOAuth(oauth).then((user) => {
        // successful
        console.log("Register/login successful, please wait...");
      }).catch((error) => {
        // error
        console.log(error.toString());
        this.setState({
          status: error.toString(),
          displayLastState: this.state.displayState,
          displayState: displayAuthError,
          busy: false,
          googleBusy: false,
          facebookBusy: false
        })
      });
    });
  }

  authWithOAuthWithLink = (oauth, e, authContext) => {
    e.preventDefault();
    // flag busy
    this.setState({
      busy: true,
      googleBusy: oauth === Consts.OAUTH_PROVIDERS.GOOGLE ? true : false,
      facebookBusy: oauth === Consts.OAUTH_PROVIDERS.FACEBOOK ? true : false
    }, () => {
      // gen link
      let generateLinkAccess = FirebaseFuncts.httpsCallable("generateLinkAccess");
      generateLinkAccess({
        method: oauth,
      }).then((result) => {
        // register user
        const key = result.data.key;
        // auth with oauth
        authContext.authenticateOAuth(oauth).then((user) => {
          // successful
          console.log("Register/login successful, please wait...");
          // now finalize link
          let finalizeLinkAccess = FirebaseFuncts.httpsCallable("finalizeLinkAccess");
          finalizeLinkAccess({
              key: key,
              uid: user.uid,
              email: user.email
            }).then((result) => {
              // all good
            }).catch((error) => {
              console.log(error.toString());
              this.setState({
                status: error.toString(),
                displayLastState: this.state.displayState,
                displayState: displayAuthError,
                busy: false
              })
            });
        }).catch((error) => {
          // error
          console.log(error.toString());
          this.setState({
            status: error.toString(),
            displayLastState: this.state.displayState,
            displayState: displayAuthError,
            busy: false,
            googleBusy: false,
            facebookBusy: false
          })
        });
      }).catch((error) => {
        console.log(error.toString());
        this.setState({
          status: error.toString(),
          displayLastState: this.state.displayState,
          displayState: displayAuthError,
          busy: false
        })
      });
    });
  }

  displayAuthNewEmail = () => {
    this.setState({
      displayLastState: this.state.displayState,
      displayState: displayAuthNewEmail
    }, () => {
      this.scrollTo("auth-email-signup")
    })
  }

  displayAuthLoginEmail = () => {
    this.setState({
      displayState: displayAuthLoginEmail
    }, () => {
      this.scrollTo("auth-email-login")
    })
  }

  render = () => {
    return (
      <React.Fragment>
        <Authentication onAuthenticate={this.onAuthenticate} onNotAuthenticated={this.onNotAuthenticated} />
        {this.props.isMobile ?
          <h3>Get the installation link for your PC</h3>
        :
          <h3>Create an account</h3>
        }
        <Switch condition={this.state.displayState}>
          <Case value={displayAuthAll}>
            <AuthenticationContext.Consumer>
              {authContext =>
                <React.Fragment>
                  {this.props.isMobile ?
                    <p>First of all, you need to have an account to install The Cyber Canary. As you are on a mobile device, we can send an installation link directly to your email account, allowing you to install The Cyber Canary on your PC. Don't worry, your details are only used for authentication and to keep your PC secure (read our <NavLink to="/privacy-policy" target={"_blank"}>privacy policy</NavLink>). Get the link using one of the following</p>
                  :
                    <p>First of all, you need to have an account to install The Cyber Canary. Don't worry, your details are only used for authentication and to keep your PC secure (read our <NavLink to="/privacy-policy" target={"_blank"}>privacy policy</NavLink>). If you already have an account, <NavLink to="#" onClick={(e) => this.displayAuthLoginEmail() }>sign in here</NavLink>, or create a new account using one of the following</p>
                  }
                  <br />
                  <button className="btn_hover app_btn" style={{ width: "250px", textAlign: "center" }} disabled={this.state.busy} onClick={(e) => this.displayAuthNewEmail() }>Email</button>
                  <div style={{ width: "250px", textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}>-or-</div>
                  <button className="btn_hover app_btn" style={{ width: "250px", textAlign: "center" }} disabled={this.state.busy} onClick={(e) => this.authWithOAuth(Consts.OAUTH_PROVIDERS.GOOGLE, e, authContext)}>
                    Google{this.state.googleBusy ? <React.Fragment>&nbsp;<i className="fas fa-spinner fa-spin"></i></React.Fragment> : null}
                  </button>
                  <div style={{ width: "250px", textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}>-or-</div>
                  <button className="btn_hover app_btn" style={{ width: "250px", textAlign: "center" }} disabled={this.state.busy} onClick={(e) => this.authWithOAuth(Consts.OAUTH_PROVIDERS.FACEBOOK, e, authContext)}>
                    Facebook{this.state.facebookBusy ? <React.Fragment>&nbsp;<i className="fas fa-spinner fa-spin"></i></React.Fragment> : null}
                  </button>
                  {!this.props.isMobile ?
                    <React.Fragment>
                      <div style={{ width: "250px", textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}>-or-</div>
                      <button id="nav-no-registration" className="btn_hover app_btn" style={{ width: "250px", textAlign: "center" }} onClick={(e) => this.setState({ displayState: displayAuthNoRegister })}>
                        Don't want to register
                      </button>
                    </React.Fragment> : null }
                </React.Fragment>
              }
            </AuthenticationContext.Consumer>
          </Case>
          <Case value={displayAuthNewEmail}>
            <Element name="auth-email-signup">
              <AuthenticationContext.Consumer>
                {authContext =>
                  <div className="sign_info_install">
                    {!this.props.isMobile ?
                      <React.Fragment>
                        <h3 className="f_p f_600 f_size_24 t_color3 mb_40">Sign up with email</h3>
                        <div className="row">
                          <form action="#" className="login-form sign-in-form">
                            <div className="form-group text_box">
                              <label className="f_p text_c f_400">Your email address</label>
                              <input name="email" type="text" placeholder="" onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="form-group text_box">
                              <label className="f_p text_c f_400">Create a password</label>
                              <input name="password" type="password" placeholder="" onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="row">
                              <div className="col-xs-5">&nbsp;&nbsp;
                                <button type="submit" className="btn_hover app_btn" disabled={!this.state.credentialsValidated || this.state.busy} onClick={(e) => this.authCreateUserWithEmailAndPassword(e, authContext)}>
                                  Sign up{this.state.busy ? <React.Fragment>&nbsp;<i className="fas fa-spinner fa-spin"></i></React.Fragment> : null}
                                </button>
                              </div>
                              <div className="col-xs-2">&nbsp;</div>
                              <div className="col-xs-5">
                                <button type="submit" className="btn_hover app_btn" disabled={this.state.busy} onClick={(e) => this.setState({ displayLastState: this.state.displayState, displayState: displayAuthAll })}>Cancel</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </React.Fragment>
                    :
                    <React.Fragment>
                      <h3 className="f_p f_600 f_size_24 t_color3 mb_40">Send link to</h3>
                      <div className="row">
                        <form action="#" className="login-form sign-in-form">
                          <div className="form-group text_box">
                            <label className="f_p text_c f_400">Your email address</label>
                            <input name="email" type="text" placeholder="" onChange={(e) => this.handleChange(e)} />
                          </div>
                          <div className="row">
                            <div className="col-xs-5">&nbsp;&nbsp;
                              <button type="submit" className="btn_hover app_btn" disabled={!this.state.credentialsValidated || this.state.busy} onClick={(e) => this.authCreateUserWithEmailAndLink(e, authContext)}>
                                Send{this.state.busy ? <React.Fragment>&nbsp;<i className="fas fa-spinner fa-spin"></i></React.Fragment> : null}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </React.Fragment>
                    }
                  </div>
                }
              </AuthenticationContext.Consumer>
            </Element>
          </Case>
          <Case value={displayAuthLoginEmail}>
            <Element name="auth-email-login">
              <AuthenticationContext.Consumer>
                {authContext =>
                  <div className="sign_info_install">
                    <h3 className="f_p f_600 f_size_24 t_color3 mb_40">Login with email</h3>
                    <div className="row">
                      <form action="#" className="login-form sign-in-form">
                        <div className="form-group text_box">
                          <label className="f_p text_c f_400">Email address</label>
                          <input name="email" type="text" placeholder="" onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className="form-group text_box">
                          <label className="f_p text_c f_400">Password</label>
                          <input name="password" type="password" placeholder="" onChange={(e) => this.handleChange(e)} />
                        </div>
                        <button type="submit" className="btn_hover app_btn" disabled={!this.state.credentialsValidated || this.state.busy} onClick={e => this.authWithEmailAndPassword(e, authContext)}>
                          Login{this.state.busy ? <React.Fragment>&nbsp;<i className="fas fa-spinner fa-spin"></i></React.Fragment> : null}
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button type="submit" className="btn_hover app_btn" disabled={this.state.busy} onClick={(e) => this.setState({ displayLastState: this.state.displayState, displayState: displayAuthAll })}>Cancel</button>
                      </form>
                    </div>
                  </div>
                }
              </AuthenticationContext.Consumer>
            </Element>
          </Case>
          <Case value={displayAuthNoRegister}>
            <React.Fragment>
              <p>Registration is required because we use web based authentication to configure and manage The Cyber Canary. If you have concerns, check out our <NavLink to="/privacy-policy" target={"_blank"}>privacy policy</NavLink> or contact us via our <a href="https://support.division-m.com" target={"_blank"} rel="noopener noreferrer">support platform</a>.</p><br />
              <button type="submit" className="btn_hover app_btn" disabled={this.state.busy} onClick={(e) => this.setState({ displayState: displayAuthAll })}>Ok</button>
            </React.Fragment>
          </Case>
          <Case value={displayAuthError}>
            <React.Fragment>
              <p><span style={{ color: "red" }}><i className="fas fa-exclamation"></i>&nbsp;{this.state.status}</span></p>
              <small>You want to <NavLink to="#" onClick={e => this.authTryAgain()}>try again</NavLink>?</small>
            </React.Fragment>
          </Case>
          <Default>
            <AuthenticationContext.Consumer>
              {authContext =>
                <React.Fragment>
                  {!this.props.isMobile ?
                    <React.Fragment>
                      <h4><i className="far fa-check"></i>&nbsp;Authentication complete!</h4>
                      <small>Logged in as {authContext.state.email}, not you? <NavLink to="#" onClick={e => this.logout(e, authContext)}>logout</NavLink></small>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <h4><i className="far fa-check"></i>&nbsp;Link sent!</h4>
                      <small>Open the email on the target PC, and follow the installation link</small>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </AuthenticationContext.Consumer>
          </Default>
        </Switch>
      </React.Fragment>
    );
  }
}