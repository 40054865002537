import React from 'react';
import { isDev } from "config/utilities"
import { ComponentAuthentication } from "./component-authentication"
import { InstallLicenseAgreement } from "./install-license-agreement";
import { InstallTermsAndConditions } from "./install-ts-and-cs";

const displayAuth = 0;
const displayAuthNewEmail = 1;
const displayAuthComplete = 2;

var scroll = require('react-scroll');
var Element = scroll.Element;
var scroller = scroll.scroller;
var lastScrollSection = null;

class InstallBodyMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayState: displayAuth,
      displayLastState: -1,      
    }
  }  

  scrollTo = (section) => {
    // dont scroll to last position
    if (section === lastScrollSection)
      return;

    let options = {
      smooth: true,            
      offset: -100,
      duration: 500,
      isDynamic: true
    };
    scroller.scrollTo(section, options);
    lastScrollSection = section;
  }

  handleChange = (e) => {
    // update state
    this.setState({ [e.target.name]: e.target.value }, () => {
      // validate
      this.setState({ credentialsValidated: (this.state.email.indexOf("@") !== -1 && this.state.email.indexOf(".") !== -1 && this.state.password.length > 0) });
    });
  }

  onAuthenticated = (user) => {    
    this.setState({
      user: user,
      displayState: this.state.displayState === displayAuthComplete ? displayAuthComplete : displayAuth
    });
  }

  displayResetForm = () => {
    this.setState({
      displayLastState: this.state.displayState,
      displayState: displayAuth
    }, () => {
      this.scrollTo("install-authenticate");
    })
  }

  displayEmailOption = () => {
    this.setState({
      displayLastState: this.state.displayState,
      displayState: displayAuthNewEmail
    }, () => {
      this.scrollTo("install-authenticate-email");
    })
  }

  render = () => {
    return (
      <React.Fragment>        
        <section className="seo_features_one sec_pad">
          <div className="container">                        
            {this.state.showLicAgree ?
              <InstallLicenseAgreement {...this.props} onClose={(agree) => this.closeAgreements(agree)} /> : null }
            {this.state.showTsAndCs ?
              <InstallTermsAndConditions {...this.props} onClose={(agree) => this.closeAgreements(agree)} /> : null }
            <div className="row flex-row-reverse">
              <div className="col-lg-5">
                <div className="seo_features_img">
                  <div className="round_circle"></div>
                  <div className="round_circle two"></div>
                  <img src={require('../img/seo/features_img.png')} alt="" />
                </div>
              </div>
              <div id="install-body" className="col-lg-7">                
                <div className="seo_features_content">                    
                  <h2>
                    Getting started
                    {isDev() ? <span>&nbsp;[DEV]</span> : null}
                  </h2>
                  <p>Getting started with The Cyber Canary is quick and easy!</p>
                  {/* authenticate */}
                  <Element name="install-authenticate">
                    <div className="media seo_features_item">
                      <div className="icon two" style={{ paddingTop: "5px" }}><i className={`far fa-lock fa-2x`} /></div>
                      <div className="media-body">
                        <ComponentAuthentication {...this.props} onAuthenticated={this.onAuthenticated} isMobile={true} />
                      </div>
                    </div>
                  </Element>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default InstallBodyMobile;