import React from 'react';

const HomeAbout = () => {
  return (
    <section id="home-about" className="analytices_list_area security_list_area">
      <img className="macbook_a" src={require("../img/mackbook_tcc.png")} alt="" />
      <img className="lock" src={require("../img/home-security/padlock.png")} alt="" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="hosting_title analytices_title">
              <h2><span>Protect what’s yours with</span>The Cyber Canary</h2>
              <p>The Cyber Canary is the first and only one-step solution to detecting and preventing ransomware attacks. The Cyber Canary watches for suspect activity, and terminates any malicious application before it can take hold on your system! <a id="nav-video-ransomware-demo" href="https://youtu.be/57kJQxxAvPg" target="_blank" rel="noopener noreferrer">Click here</a> to see it in action.</p>
            </div>
            <ul className="analytices_list">
              <li>Zero configuration, install and forget</li>
              <li>Uses “canary” file technology</li>
              <li>All user accounts protected</li>
              <li>Termination of a suspect process</li>
              <li>Onscreen and email alerts</li>
              <li>Powered by Division-M's <a href="https://www.anvil-fs.com" target="_blank" rel="noopener noreferrer">Anvil File Security</a> engine</li>
              <li>Fully featured 30 day trial, once <a href="/install" target="_blank" rel="noopener noreferrer">installed</a>, you can <a href="/testing" target="_blank" rel="noopener noreferrer">test</a> and see The Cyber Canary in action</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeAbout;