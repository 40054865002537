import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Consts } from "config/consts";

class HomePurchase extends Component {
  constructor() {
    super();
    this.state = {
      base: 0,
      busy: false,
    }

    this.pricing = [
      {
        name: "Home",
        price: "From $" + Consts.STRIPE.REL.PLAN_STANDARD_1[Consts.STRIPE.FIELD.PLAN_PRICE_YEAR] + " / year, for " + Consts.STRIPE.REL.PLAN_STANDARD_1[Consts.STRIPE.FIELD.PLAN_DEVICES] + " devices",
        stripe: "standard"
      },
      {
        name: "Premium",
        price: "From $" + Consts.STRIPE.REL.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_PRICE_YEAR] + " / year, for " + Consts.STRIPE.REL.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_DEVICES] + " devices",
        stripe: "premium"
      },
      {
        name: "Enterprise client",
        price: "From $" + Consts.STRIPE.REL.PLAN_ENT_CLI_1[Consts.STRIPE.FIELD.PLAN_PRICE_YEAR] + " / year, for " + Consts.STRIPE.REL.PLAN_ENT_CLI_1[Consts.STRIPE.FIELD.PLAN_DEVICES] + " devices",
        stripe: "enterprise_client"
      },
      {
        name: "Enterprise server",
        price: "$" + Consts.STRIPE.REL.PLAN_ENT_SVR_1[Consts.STRIPE.FIELD.PLAN_PRICE_MONTH] + " / month, for " + Consts.STRIPE.REL.PLAN_ENT_SVR_1[Consts.STRIPE.FIELD.PLAN_DEVICES] + " server",
        stripe: "enterprise_server"
      }
    ]

    this.list = [
      {
        title: "Protect up to 3 machines",
        sub: "Limited time offer",
        icon: ["icon_check_alt2", "icon_check_alt2", "icon_close", "icon_close"],
        tip: "You can use this license to protect up to 3 machine, this is a limited time offer!",
      },
      {
        title: "Protect 20+ machines",
        sub: null,
        icon: ["icon_close", "icon_close", "icon_check_alt2", "icon_close"],
        tip: "You can use this license to protect 10 or more enterprise clients.",
      },
      {
        title: "Notifications and emails",
        sub: null,
        icon: 'icon_check_alt2',
        icon: ["icon_check_alt2", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "If there is malicious activity detect, The Cyber Canary will display a full screen message, and send emails.",
      },
      {
        title: "Rogue process termination",
        sub: null,
        icon: 'icon_check_alt2',
        icon: ["icon_check_alt2", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "If there is malicious activity detect, The Cyber Canary will terminate the malicious process/application.",
      },
      {
        title: "Intrusion detection",
        sub: null,
        icon: ["icon_close", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "The Cyber Canary will monitor for read activity on canary files, and respond if detected.",
      },
      {
        title: "Monitor user folders",
        sub: null,
        icon: ["icon_check_alt2", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "Monitor all users folders",
      },
      {
        title: "Monitor custom folders",
        sub: null,
        icon: ["icon_close", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "Monitor one or more custom folders",
      },
      {
        title: "Commanded shut down",
        sub: null,
        icon: ["icon_close", "icon_check_alt2", "icon_close", "icon_check_alt2"],
        tip: "If there is malicious activity detect, The Cyber Canary will send a shut down command.",
      },
      {
        title: "Custom installation URL",
        sub: null,
        icon: ["icon_close", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "Multi machine licenses have access to an installation URL specific to your license.",
      },
      {
        title: "Snapshot installation",
        sub: null,
        icon: ["icon_close", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "Distribute custom configuration snapshots to client machines.",
      },
      {
        title: "Online support",
        sub: null,
        icon: ["icon_check_alt2", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "Support via the Division-M support site.",
      },
      {
        title: "30 day guarantee",
        sub: null,
        icon: ["icon_check_alt2", "icon_check_alt2", "icon_check_alt2", "icon_check_alt2"],
        tip: "30 day, full money back guarantee.",
      }
    ]
  }

  render() {
    return (
      <section id="home-purchase" className="pricing_area_four sec_pad">
        <div className="container">
          <div className="hosting_title security_title text-center">
            <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Purchase a license for</span> The Cyber Canary</h2>
          </div>
          <div className={this.state.base === 0 ? "price_info_two" : "price_info_two price_info_three"}>
            <div className="price_head">
              <div className="p_head time">
                <h4>
                  {this.state.base === 0 ? "Home licensing": "Enterprise licensing"}<br/>
                  <small>
                    <NavLink to="#" onClick={(e) => this.setState({ base: this.state.base === 0 ? 2 : 0 }) }>
                      {this.state.base === 0 ? "Switch to enterprise pricing": "Switch to home pricing"}
                    </NavLink>
                  </small>
                </h4>
              </div>
              <div className="p_head">
                <h5>{this.pricing[this.state.base].name}</h5>
                <p>{this.pricing[this.state.base].price}</p>
              </div>
              <div className="p_head">
                <h5>{this.pricing[this.state.base + 1].name}</h5>
                <p>{this.pricing[this.state.base + 1].price}</p>
              </div>
            </div>
            <div className="price_body">
              {this.list.map((list, i) => {
                return (
                  <div className="pr_list" key={i}>
                    <div className="price_item">
                      <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title={list.tip} data-original-title="">{list.title}
                        <br/>{list.sub ? <small>{list.sub}</small> : <small>&nbsp;</small> }
                      </h5>
                    </div>
                    <div className="price_item" data-title="Home">
                      <h5 className="check"><i className={list.icon[this.state.base]}></i></h5>
                    </div>
                    <div className="price_item" data-title="Pro">
                      <h5 className="check"><i className={list.icon[this.state.base + 1]}></i></h5>
                    </div>
                  </div>
                )
              })}
              <div className="pr_list">
                <div className="price_item"></div>
                <div className="price_item">
                  <button id={this.state.base === 0 ? "nav-purchase-standard" : "nav-purchase-enterprise-client"} className="price_btn btn_hover" onClick={(e) => this.props.displayStripe(this.pricing[this.state.base].stripe)}>Purchase</button>
                  {this.state.base > 0 ?
                    <React.Fragment>
                      <br/><a href="https://support.division-m.com" target="_blank" rel="noopener noreferrer">Contact support</a> for more options.click here
                    </React.Fragment> : null }
                </div>
                <div className="price_item">
                  <button id={this.state.base === 0 ? "nav-purchase-premium" : "nav-purchase-enterprise-server"} className="price_btn btn_hover" onClick={(e) => this.props.displayStripe(this.pricing[this.state.base + 1].stripe)}>Purchase</button>
                  {this.state.base > 0 ?
                    <React.Fragment>
                      <br/><a href="https://support.division-m.com" target="_blank" rel="noopener noreferrer">Contact support</a> for more options.click here
                    </React.Fragment> : null }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default HomePurchase;