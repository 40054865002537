import React from 'react';
import NavbarSimple from "../components/navbar-simple"
import HowWorksBody from '../components/how-works-body';
import HomeFooter from "../components/home-footer";

export const HowWorks = () => (
  <div className="body_wrapper">
    <NavbarSimple />
    <HowWorksBody />
    <HomeFooter />
  </div>
)