import React from 'react';
import { Link as LinkScroll } from 'react-scroll';
import { Consts } from "config/consts";

const HomeBanner = () => {
  return (
    <section className="h_security_banner_area">
      <img className="p_absoulte s_shap" src={require("../img/home-security/shap.png")} alt="" />
      <div className="s_round one"></div>
      <div className="s_round two"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="security_banner_content">
              <h2 className="wow fadeInUp" data-wow-delay="0.3s">
                {!Consts.SYSTEM.RELEASE ? "[DEV] " : null }
                The canary in your cyber coalmine, keeping you safe as you work and play online
              </h2>
              <p className="f_400 w_color l_height28 wow fadeInUp" data-wow-delay="0.4s">Get ransomware and malware protection in minutes</p>
              <div className="action_btn d-flex align-items-center mt_40 wow fadeInUp" data-wow-delay="0.6s">
                <LinkScroll id="nav-get-protected" href="#home-install" to="home-install" className="btn_hover app_btn" spy={true} smooth={true} offset={-70} duration={750}>Subscribe</LinkScroll>
                <LinkScroll id="nav-about" href="#home-about" to="home-about" className="w_btn" spy={true} smooth={true} offset={-70} duration={750}>What's it all about</LinkScroll>
                <LinkScroll id="nav-features" href="#home-features" to="home-features" className="w_btn" spy={true} smooth={true} offset={-70} duration={750}>Features</LinkScroll>
              </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
            <div className="new_startup_img">
              <img src={require("../img/canary_banner.png")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeBanner;