import React from 'react';
import Fade from 'react-reveal/Fade';

class HowWorksBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render = () => {
    return (
      <React.Fragment>
        <section className="seo_features_one sec_pad">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-5">
                <div className="seo_features_img">
                  <div className="round_circle"></div>
                  <div className="round_circle two"></div>
                  <img src={require('../img/seo/features_img_three.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-7">
                <Fade bottom cascade>
                  <div className="seo_features_content">
                    <h2>
                      How The Cyber Canary protects your system
                    </h2>
                    <p>Here is an overview of how The Cyber Canary monitors and alerts you to ransomware and other malware threats</p>
                    <div className="media seo_features_item">
                      <div className="icon" style={{ paddingTop: "5px" }}><i className="far fa-hood-cloak fa-2x" /></div>
                      <div className="media-body">
                        <h3>The bad guys</h3>
                        <p>Detecting and alerting against malware can be quite difficult. Most anti-virus solution use heuristic analysis to determine the good from the bad. While this can work on well established and understood malware, it is not great at detecting new variants.</p>
                      </div>
                    </div>
                    <div className="media seo_features_item">
                      <div className="icon two" style={{ paddingTop: "5px" }}><i className="far fa-digging fa-2x" /></div>
                      <div className="media-body">
                        <h3>The coalmine</h3>
                        <p>Once planted, ransomware does not require administrative access, it can do its thing with a standard user account, making it much easier for users to get stung.</p>
                        <p>During an attack, the ransomware need to get its job done as fast as possible, otherwise, it risks detection. So, it starts "ripping" through your files, encrypting them as it goes.</p>
                      </div>
                    </div>
                    <div className="media seo_features_item">
                      <div className="icon" style={{ paddingTop: "5px" }}><i className="far fa-crow fa-2x" /></div>
                      <div className="media-body">
                        <h3>The canary</h3>
                        <p>We use this indiscriminate "ripping" to our advantage, by laying down "canary files". Canary files are not simply random files placed in your folders, these are virtual files that we have total control over. We can detect any access against them, down to the byte... in real-time. If any process so much as reads a single byte, we know about it!</p>
                      </div>
                    </div>
                    <div className="media seo_features_item">
                      <div className="icon two" style={{ paddingTop: "5px" }}><i className="far fa-file fa-2x" /></div>
                      <div className="media-body">
                        <h3>The file</h3>
                        <p>So what exactly is a canary file? It is identical to a real file, however, it only ever exists while The Cyber Canary is active. And because we are using the <a href="https:\\www.anvil-fs.com" rel="noopener noreferrer" target="_blank">Anvil</a> platform, we are able to actively filter these files from any application (by default, we hide them from Windows Explorer)</p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default HowWorksBody;
