import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

var config = {
  apiKey: "AIzaSyC3f0Fcp9SMJnhGADRCDxHC8vQoCYkQw3k",
  authDomain: "anvil-v1.firebaseapp.com",
  databaseURL: "https://anvil-v1.firebaseio.com",
  projectId: "anvil-v1",
  storageBucket: "anvil-v1.appspot.com",
  messagingSenderId: "651250976726",
  appId: "1:651250976726:web:4eba0456d633cf01"
};

export const Firebase = firebase.initializeApp(config);
export const Firestore = Firebase.firestore();
export const FirebaseFuncts = firebase.functions();

// set timestamp option (prevent warning)
const settings = {
  timestampsInSnapshots: true
}
Firestore.settings(settings);

export function firebaseUserUID() {
  let user = Firebase.auth().currentUser;
  if (user)
    return user.uid;
  return null;
}

export function firebaseUploadImage(path, name, base64URL, callbackStartUploading, callbackSuccess, callbackError) {
  var storageRef = Firebase.storage().ref();

  //determine image format
  var metadata = { contentType: '' };
  var imageExt = null;
  if (base64URL.indexOf('data:image/png;base64') !== -1) {
    metadata.contentType = 'image/png';
    imageExt = '.png';
  } else if (base64URL.indexOf('data:image/jpeg;base64') !== -1) {
    metadata.contentType = 'image/jpeg';
    imageExt = '.jpeg';
  } else if (base64URL.indexOf('data:image/jpg;base64') !== -1) {
    metadata.contentType = 'image/jpg';
    imageExt = '.jpg';
  } else if (base64URL.indexOf('data:image/gif;base64') !== -1) {
    metadata.contentType = 'image/gif';
    imageExt = '.gif';
  }

  // do we have an image?
  if (!imageExt) {
    callbackError('Invalid image format, profile image not updated.');
    return;
  }

  // get path
  var imageRef = storageRef.child(path + '/' + name + imageExt);

  // upload...
  try {
    callbackStartUploading();
    imageRef.putString(base64URL, 'data_url', metadata).then((snapshot) => {
      // return url
      imageRef.getDownloadURL().then(function (url) {
        callbackSuccess(url);
      }).catch((error) => {
        callbackError(error.message);
      });
    });
  } catch (e) {
    callbackError(e.message);
  }
};

export function firebaseUploadDocument(path, name, ext, base64URL, callbackStartUploading, callbackSuccess, callbackError) {
  var storageRef = Firebase.storage().ref();

  //determine image format
  var md = base64URL.split(";")[0];
  var metadata = { contentType: md };

  // do we have an image?
  if (!ext || ext.indexOf(".") === -1) {
    callbackError('Invalid image format, profile image not updated.');
    return;
  }

  // get path
  var docRef = storageRef.child(path + '/' + name + ext);

  // upload...
  try {
    callbackStartUploading();
    docRef.putString(base64URL, 'data_url', metadata).then((snapshot) => {
      // return url
      docRef.getDownloadURL().then(function (url) {
        callbackSuccess(url);
      }).catch((error) => {
        callbackError(error.message);
      });
    });
  } catch (e) {
    callbackError(e.message);
  }
};

export function updateSystemPublic(data) {
  console.log(data)
  Firestore.collection("system_public").doc("version").set(data);
}