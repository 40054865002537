import React from 'react';
import NavbarSimple from "../components/navbar-simple"
import TestingBody from '../components/testing-body';
import HomeFooter from "../components/home-footer";

export const Testing = () => (
  <div className="body_wrapper">
    <NavbarSimple />
    <TestingBody />
    <HomeFooter />
  </div>
)