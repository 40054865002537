import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from "reactstrap";
import { LicenseAgreementText } from "config/consts";

export class InstallLicenseAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agree: false
    }
  }

  closeDialog = (agree) => {
    if (!this.props.onClose)
      return;
    this.props.onClose(agree);
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <Modal className="manage-modal" isOpen={true} size="lg" centered={true} backdrop={true}>
        <ModalHeader close={closeBtn}>
          License agreement
        </ModalHeader>
        <ModalBody id="classic-modal-slide-description">          
          <FormGroup>
            <Input type="textarea" value={LicenseAgreementText} readOnly={true} style={{ minHeight: "300px", color: "#0E0E0E" }} />
          </FormGroup>
          <div className="text-right">
            <Button color="danger" onClick={(e) => this.closeDialog(false)}>Close</Button>
          </div>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
    );
  }
}