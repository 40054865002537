import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { Link as LinkScroll } from 'react-scroll'

class Navbar extends Component {
  render() {
    let { mClass, nClass, cClass, slogo, hbtnClass } = this.props;
    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <header className="header_area">
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>
              <Link className={`navbar-brand ${slogo}`} to="/">
                <img src={require("../img/canary_logo_light.png")} alt="The Cyber Canary" />
                <img src={require("../img/canary_logo_dark.png")} alt="The Cyber Canary" />
              </Link>
              <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="menu_toggle">
                  <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <a href="#home-main" className="nav-link" role="button" aria-haspopup="true" aria-expanded="false">Home</a>
                  </li>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <LinkScroll id="nav-about" href="#home-about" to="home-about" spy={true} smooth={true} offset={-70} duration={750} className="nav-link">
                      About
                    </LinkScroll>
                  </li>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <LinkScroll id="nav-features" href="#home-features" to="home-features" spy={true} smooth={true} offset={-70} duration={750} className="nav-link">
                      Features
                    </LinkScroll>
                  </li>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <LinkScroll id="nav-purchase" href="#home-purchase" to="home-purchase" spy={true} smooth={true} offset={-70} duration={750} className="nav-link">
                      Purchase
                    </LinkScroll>
                  </li>
                  <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                    <a id="nav-video-ransomware-demo" className="nav-link" onClick={(e) => this.props.onVideoClick()}>
                      Video
                    </a>
                  </li>
                </ul>
                <LinkScroll id="nav-get-protected" className={`btn_get btn_hover ${hbtnClass}`} href="#home-install" to="home-install" spy={true} smooth={true} offset={-70} duration={750}>Free trial</LinkScroll>
                <a id="nav-portal" className={`btn_get btn_hover ${hbtnClass}`} href="https://portal.thecybercanary.com" target="_blank" rel="noopener noreferrer">Dashboard</a>
              </div>
            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default Navbar;