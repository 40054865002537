import React from "react";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CardElement } from "@stripe/react-stripe-js";
import { Consts } from "config/consts";
import { FirebaseFuncts } from "config/firebase";
import { AuthenticationContext } from "context/authentication";
import { ComponentAuthentication } from "./component-authentication"

const displayOptions = 0;
const displayAuth = 1;
const displayPayment = 2;
const displayComplete = 3;

const stripeOptions = {
  style: {
    base: {
      fontSize: "20px",
      color: "#424770",
      letterSpacing: "0.025em",
      fontFamily: "Source Code Pro, monospace",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#9e2146"
    }
  }
};

export class StripePayment extends React.Component {
  constructor(props) {
    super(props);

    let plan = null;  // default
    if (props.planType === "standard")
      plan = Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_STANDARD_1 : Consts.STRIPE.DEV.PLAN_STANDARD_1;
    if (props.planType === "premium")
      plan = Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_PREMIUM_1 : Consts.STRIPE.DEV.PLAN_PREMIUM_1;
    if (props.planType === "enterprise_client")
      plan = Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_ENT_CLI_1 : Consts.STRIPE.DEV.PLAN_ENT_CLI_1;
    if (props.planType === "enterprise_server")
      plan = Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_ENT_SVR_1 : Consts.STRIPE.DEV.PLAN_ENT_SVR_1;

    this.state = {
      displayState: displayAuth,
      busy: false,
      user: null,
      plan: plan,
      agree: false,
      txtPromoCode: "",
      keyExisting: "",
      promoCode: "",
      validPromoCode: false,
      showLicenseType: true,
      error: null,
      licenseKey: null,
      licenseMessage: null,
      licenseInstalled: false,
      onCloseData: null
    }
  }

  onAuthenticated = (user) => {
    this.setState({
      user: user,
      displayState: user ? displayPayment : displayAuth
    })
  }

  createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#FFFFFF',
          lineHeight: '1.5em',
          fontFamily: "Poppins, sans-serif",
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

  handleChange = (e) => {
    // update state
    this.setState({
      [e.target.name]: e.target.value }, () => {
    });
  }

  handleSubmitStripe = async (e) => {
    e.preventDefault();

    this.setState({
      error: null,
      busy: true
    });

    const {stripe, elements} = this.props;
    const cardElement = elements.getElement('card');
    const {error, token} = await stripe.createToken(cardElement, {
      email: this.state.user.email
    });

    // error?
    if (error) {
      this.setState({
        error: error.message,
        busy: false
      });
      console.log(error.message);
      return;
    }

    // all good, set up license
    if (token) {
      let subscription = FirebaseFuncts.httpsCallable("createStripeSubscription");
        subscription({
          dev: !Consts.SYSTEM.RELEASE,
          apiKey: Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.API_KEY : Consts.STRIPE.DEV.API_KEY,
          tokenId: token.id,
          planId: this.state.plan[Consts.STRIPE.FIELD.PLAN_ID],
          email: this.state.user.email,
          machineKey: null,
          machineName: null,
          licenseType: this.state.plan[Consts.STRIPE.FIELD.PLAN_TYPE],
          licenseKey: "",
          licenseDays: 365,
          licenseDevices: this.state.plan[Consts.STRIPE.FIELD.PLAN_DEVICES],
          value: this.state.plan[Consts.STRIPE.FIELD.PLAN_PRICE_YEAR]
        }).then((result) => {
          this.setState({ busy: false });
          console.log(result.data.text + " License key: " + result.data.licenseKey);

          // set onclose
          let oc = {
            planId: result.data.planId,
            licenseKey: result.data.licenseKey,
            email: result.data.email
          }

          // done!
          this.setState({
            onCloseData: oc,
            licenseKey: result.data.licenseKey,
            licenseMessage: result.data.text,
            displayState: displayComplete
          })

          // attempt to send license data to service
          window.roFolderWall.SetLicense(this.state.user.uid, result.data.planId, result.data.licenseKey, result.data.email, (license) => {
            console.log("License installed: %o", license);
            this.setState({
              licenseInstalled: license
            });
          }, (error) => {
            console.log(error);
          });

          return;

        }).catch((error) => {
          this.setState({
            error: error.message,
            busy: false
          });
          console.log(error.message);
          return;
        });
    }
  };

  receiveToken = (token, addresses) => {
    console.log(token);
  }

  closeDialog = (license) => {
    if (!this.props.onClose)
      return;
    this.props.onClose(license);
  }

  setNewPlan = (e) => {
    let pid = e.target.value;
    let newPlan = null;

    // release
    if (pid === Consts.STRIPE.REL.PLAN_STANDARD_1[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_STANDARD_1;

    if (pid === Consts.STRIPE.REL.PLAN_STANDARD_2[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_STANDARD_2;

    if (pid === Consts.STRIPE.REL.PLAN_STANDARD_3[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_STANDARD_3;

    if (pid === Consts.STRIPE.REL.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_PREMIUM_1;

    if (pid === Consts.STRIPE.REL.PLAN_PREMIUM_2[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_PREMIUM_2;

    if (pid === Consts.STRIPE.REL.PLAN_PREMIUM_3[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_PREMIUM_3;

    if (pid === Consts.STRIPE.REL.PLAN_ENT_CLI_1[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_ENT_CLI_1;

    if (pid === Consts.STRIPE.REL.PLAN_ENT_CLI_2[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_ENT_CLI_2;

    if (pid === Consts.STRIPE.REL.PLAN_ENT_CLI_3[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_ENT_CLI_3;

    if (pid === Consts.STRIPE.REL.PLAN_ENT_SVR_1[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.REL.PLAN_ENT_SVR_1;

    // dev
    if (pid === Consts.STRIPE.DEV.PLAN_STANDARD_1[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.DEV.PLAN_STANDARD_1;

    if (pid === Consts.STRIPE.DEV.PLAN_STANDARD_2[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.DEV.PLAN_STANDARD_2;

    if (pid === Consts.STRIPE.DEV.PLAN_STANDARD_3[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.DEV.PLAN_STANDARD_3;

    if (pid === Consts.STRIPE.DEV.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.DEV.PLAN_PREMIUM_1;

    if (pid === Consts.STRIPE.DEV.PLAN_PREMIUM_2[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.DEV.PLAN_PREMIUM_2;

    if (pid === Consts.STRIPE.DEV.PLAN_PREMIUM_3[Consts.STRIPE.FIELD.PLAN_ID])
      newPlan = Consts.STRIPE.DEV.PLAN_PREMIUM_3;

    console.log(newPlan);

    if (!newPlan)
      return;

    this.setState({
      plan: newPlan,
      displayState: this.state.user ? displayPayment : displayAuth
    })
  }

  renderOptions = (authContext) => {
    return (
      <div className="media seo_features_item">
        <div className="icon two" style={{ paddingTop: "5px" }}><i className={`far fa-laptop-house fa-2x`} /></div>
        <div className="media-body">
          {this.state.displayState === displayOptions ?
            <React.Fragment>
              <div className="sign_info_install">
                <h3>Select options</h3>
                <div className="row">
                  <form action="#" style={{ width: "100%" }}>
                    <div className="form-group text_box">
                      <label className="f_p text_c f_400">Devices</label>
                      <select name="devices" className="form-control" onChange={(e) => this.setNewPlan(e)} value={this.state.plan[Consts.STRIPE.FIELD.PLAN_ID]}>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_STANDARD_1[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_STANDARD_1[Consts.STRIPE.FIELD.PLAN_ID] }>Standard, 3 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_STANDARD_2[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_STANDARD_2[Consts.STRIPE.FIELD.PLAN_ID] }>Standard, 5 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_STANDARD_3[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_STANDARD_3[Consts.STRIPE.FIELD.PLAN_ID] }>Standard, 10 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_PREMIUM_1[Consts.STRIPE.FIELD.PLAN_ID] }>Premium, 3 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_PREMIUM_2[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_PREMIUM_2[Consts.STRIPE.FIELD.PLAN_ID] }>Premium, 5 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_PREMIUM_3[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_PREMIUM_3[Consts.STRIPE.FIELD.PLAN_ID] }>Premium, 10 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_ENT_CLI_1[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_ENT_CLI_1[Consts.STRIPE.FIELD.PLAN_ID] }>Enterprise client, 20 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_ENT_CLI_2[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_ENT_CLI_2[Consts.STRIPE.FIELD.PLAN_ID] }>Enterprise client, 50 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_ENT_CLI_3[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_ENT_CLI_3[Consts.STRIPE.FIELD.PLAN_ID] }>Enterprise client, 100 devices</option>
                        <option value={ Consts.SYSTEM.RELEASE ? Consts.STRIPE.REL.PLAN_ENT_SVR_1[Consts.STRIPE.FIELD.PLAN_ID] : Consts.STRIPE.DEV.PLAN_ENT_SVR_1[Consts.STRIPE.FIELD.PLAN_ID] }>Enterprise server, 1 server</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </React.Fragment>
          :
            <React.Fragment>
              <h3>License options</h3>
              <p>Select how many machines you would like to license</p>
              <h4><i className="far fa-check"></i>&nbsp;{ this.state.plan[Consts.STRIPE.FIELD.PLAN_NAME] }, {this.state.plan[Consts.STRIPE.FIELD.PLAN_DEVICES] } devices<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>${this.state.plan[Consts.STRIPE.FIELD.PLAN_PRICE_MONTH]} / month, paid { this.state.plan[Consts.STRIPE.FIELD.PLAN_PAY_TYPE] }</small>
              </h4>
              <small>Not correct, and need to <NavLink to="#" onClick={e => this.setState({ displayState: displayOptions })}>change</NavLink>?</small>
            </React.Fragment>
          }
        </div>
      </div>
    );
  }

  renderAuthentication = (authContext) => {
    return (
      <div className="media seo_features_item">
        <div className="icon" style={{ paddingTop: "5px" }}><i className={`far fa-lock fa-2x`} /></div>
        <div className="media-body">
          <ComponentAuthentication {...this.props} onAuthenticated={this.onAuthenticated} user={authContext.state.authenticated ? authContext.state : null}/>
        </div>
      </div>
    );
  }

  renderPaymentForm = (authContext) => {
    return (
      <div className="media seo_features_item">
        <div className="icon two" style={{ paddingTop: "5px" }}><i className={`far fa-credit-card fa-2x`} /></div>
        <div className="media-body">
          {this.state.displayState === displayPayment ?
            <React.Fragment>
              <div className="sign_info_install">
                <h3>Credit card (powered bt <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a>)</h3>
                {this.state.plan[Consts.STRIPE.FIELD.PLAN_PAY_TYPE] === "annually" ?
                  <p>${this.state.plan[Consts.STRIPE.FIELD.PLAN_PRICE_YEAR]} / year, billed {this.state.plan[Consts.STRIPE.FIELD.PLAN_PAY_TYPE]}</p>
                :
                  <p>${this.state.plan[Consts.STRIPE.FIELD.PLAN_PRICE_MONTH]} / month, billed {this.state.plan[Consts.STRIPE.FIELD.PLAN_PAY_TYPE]}</p>
                }
                {this.state.error ?
                  <p><span style={{ color: "red" }}><i className="fas fa-exclamation"></i>&nbsp;{this.state.error}</span></p> : null }
                <br/><br/>
                <div className="row">
                  <form action="#" style={{ width: "100%" }}>
                    <div className="form-group text_box">
                      <CardElement
                        options={ stripeOptions }
                        onReady={() => {
                          // console.log("CardElement [ready]");
                        }}
                        onChange={event => {
                          // console.log("CardElement [change]", event);
                        }}
                        onBlur={() => {
                          // console.log("CardElement [blur]");
                        }}
                        onFocus={() => {
                          // console.log("CardElement [focus]");
                        }}
                      />
                    </div>
                    <button type="submit" className="btn_hover app_btn" disabled={this.state.busy} onClick={e => this.handleSubmitStripe(e)}>
                      Process{this.state.busy ? <span>&nbsp;<i className="fas fa-spinner fa-spin"></i></span> : null}
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="submit" className="btn_hover app_btn" disabled={this.state.busy} onClick={(e) => this.closeDialog(this.state.onCloseData)}>Cancel</button>
                  </form>
                </div>
              </div>
            </React.Fragment>
          :
            <React.Fragment>
              <h3>Credit card (powered by <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a>)</h3>
              <p>Enter your credit card detail to finalize your payment.</p>
              {this.state.displayState === displayAuth ?
                <h4>Waiting on user registration&nbsp;<i className="fas fa-spinner fa-spin"></i></h4> : null }
              {this.state.displayState === displayOptions ?<h4>Waiting on option selection&nbsp;<i className="fas fa-spinner fa-spin"></i></h4> : null }
            </React.Fragment>
          }
        </div>
      </div>
    );
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(this.state.onCloseData)}>&times;</button>;
    return (
      <AuthenticationContext.Consumer>
        {authContext =>
          <React.Fragment>
            <div className="modal-backdrop" style={{ background: "rgba(0, 0, 0, 0.8)" }}></div>
            <Modal isOpen={true} size="lg" centered={true} backdrop={true}>
            {this.state.displayState !== displayComplete ? <React.Fragment><br/><br/><br/></React.Fragment> : null}
            <ModalHeader close={closeBtn}>
              Order The Cyber Canary
            </ModalHeader>
              <ModalBody>
                <div className="container">
                  <Fade top cascade>
                    {this.state.displayState !== displayComplete ?
                      <div className="seo_features_content">
                        <p>
                          Ordering The Cyber Canary is quick and easy... once ordered, you license will be automatically installed. <strong>Important, we do not hold, or even have access to your credit card details, this is handled by <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe, Inc</a></strong>. If you require support, <a href="https://support.division-m.com" target="_blank" rel="noopener noreferrer">click here</a>.
                        </p>
                        {this.renderOptions(authContext)}
                        {this.renderAuthentication(authContext)}
                        {this.renderPaymentForm(authContext)}
                      </div>
                    :
                      <div className="seo_features_content">
                        <h3>Payment complete</h3>
                        <p>{this.state.licenseMessage}<br/>
                          License key: {this.state.licenseKey}<br/>
                          {this.state.licenseInstalled ?
                            <React.Fragment>Your license has been successfully installed!</React.Fragment>
                          :
                            <React.Fragment>Your license key has also been emailed to you. If you have not already done so, <a href="/install">install The Cyber Canary</a> and your new license will be automatically installed!</React.Fragment>
                          }
                        </p>
                        <button type="submit" className="btn_hover app_btn" disabled={this.state.busy} onClick={(e) => this.closeDialog(this.state.onCloseData)}>Done</button>
                      </div>
                    }
                  </Fade>
                </div>
              </ModalBody>
            </Modal>
          </React.Fragment>
        }
      </AuthenticationContext.Consumer>
    );
  }
}