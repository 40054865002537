import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from "reactstrap";
import { Consts } from "config/consts";

export class DialogBox extends React.Component {
  constructor(props) {
    super(props);
  }

  closeDialog = (accept) => {
    if (!this.props.onClose)
      return;
    this.props.onClose(accept);
  }

  render = () => {
    const closeBtn = <button className="close" onClick={(e) => this.closeDialog(false)}>&times;</button>;
    return (
      <Modal className="manage-modal" isOpen={true} size="md" centered={true} backdrop={true}>
        <ModalHeader close={closeBtn}>
          {this.props.title}
        </ModalHeader>
        <ModalBody id="classic-modal-slide-description">
          <FormGroup>
            {this.props.message}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {this.props.type === Consts.MESSAGE_TYPE.INFO ?
            <div className="text-right">
              <Button color="primary" onClick={(e) => this.closeDialog(false)}>Ok</Button>
            </div> : null }
          {this.props.type === Consts.MESSAGE_TYPE.ERROR ?
            <div className="text-right">
              <Button color="danger" onClick={(e) => this.closeDialog(false)}>Close</Button>
            </div> : null }
          {this.props.type === Consts.MESSAGE_TYPE.CONFIRM ?
            <React.Fragment>
              <div className="text-left">
                <Button color="primary" onClick={(e) => this.closeDialog(true)}>Ok</Button>
              </div>
              <div className="text-right">
                <Button color="danger" onClick={(e) => this.closeDialog(false)}>Cancel</Button>
              </div>
            </React.Fragment> : null }
        </ModalFooter>
      </Modal>
    );
  }
}