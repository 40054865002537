import React, { Component } from 'react';

class HomeFeaturesItem extends Component {
  render() {
    let { keyVal, itemClass, icon, title, text } = this.props;
    return (
      <div className="col-lg-6" key={keyVal}>
        <div className={`media h_security_item ${itemClass}`}>
          <div className="row">
            <div className="col-sm-2">
              <div style={{ marginTop: "50px" }}>
                <span style={{ color: "Dodgerblue" }}>
                  <i className={`far ${icon} fa-5x`} />
                </span>
              </div>
            </div>
            <div className="col-sm-1">&nbsp;</div>
            <div className="col-sm-9">
              <div className="media-body">
                <h4>{title}</h4>
                <p>{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const HomeFeatures = () => {
  return (
    <section id="home-features" className="h_security_area">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Ransomware protection with</span> The Cyber Canary</h2>
          <p><a href="/how-works" target="_blank" rel="noopener noreferrer">Click here</a> for an overview on how The Cyber Canary works.</p>
        </div>
        <div className="row">
          <HomeFeaturesItem keyVal="1" itemClass="pl_70" icon="fa-file-code" title="Australian made" text={["The Cyber Canary is designed and developed in Sydney, Australia by ", <a href="https://www.division-m.com" target="_blank" rel="noopener noreferrer">Division-M</a>, ". Over the years, ", <a href="https://www.division-m.com" target="_blank" rel="noopener noreferrer">Division-M</a>, " has created applications used by hundreds of thousands of users, worldwide, including our class leading storage pooling software, ", <a href="https://www.drivebender.com.com" target="_blank" rel="noopener noreferrer">Drive Bender</a>, "."]} />
          <HomeFeaturesItem keyVal="2" itemClass="pr_70" icon="fa-mouse" title="One-click installation" text={["There is no configuration involved. The Cyber Canary is genuinely a one-click solution to detecting and preventing a ransomware infection. Simply follow the prompts to get The Cyber Canary ", <a href="/install" target="_blank" rel="noopener noreferrer">installed</a>, ", and you are good to go."]} />
          <HomeFeaturesItem keyVal="3" itemClass="pl_70" icon="fa-badge-check" title="Set and forget" text="Once installed, The Cyber Canary requires no configuration or ongoing attention. It quietly goes about the business of monitoring your file system and keeping your files safe." />
          <HomeFeaturesItem keyVal="4" itemClass="pr_70" icon="fa-ghost" title="Canary files" text="Our exclusive canary file technology ensures that your system does not get cluttered with a bunch of random physical files. A canary file is actually a virtual file, that only exists while The Cyber Canary is monitoring your machine." />
          <HomeFeaturesItem keyVal="5" itemClass="pl_70" icon="fa-users" title="All users covered" text="The Cyber Canary, by default, monitors all user accounts on the machine, even those added after The Cyber Canary was installed. We can do this as The Cyber Canary dynamically attaches itself to all user folders at startup." />
          <HomeFeaturesItem keyVal="6" itemClass="pr_70" icon="fa-virus-slash" title="Stopping rogue processes" text="If The Cyber Canary determines an application is up to no good, it will terminate the offending process and then send notifications of the event to all registered users, ensuring that there is little or no opportunity to damage your files." />
          <HomeFeaturesItem keyVal="7" itemClass="pl_70" icon="fa-monitor-heart-rate" title="Real-time notifications" text="If the unfortunate does happen, and you get infected, The Cyber Canary will display an on-screen message and, send an email to the account holder and any other nominated member. Optionally, for server environments, the host machine can be commanded to shut down." />
          <HomeFeaturesItem keyVal="8" itemClass="pr_70" icon="fa-thumbs-up" title="Try without risk" text="The Cyber Canary has a 30 day trial, in addition to a no questions asked, 30 day money back guarantee. After installing, we show you how you can test The Cyber Canary, to ensure you are 100% protected!" />
        </div>
      </div>
    </section>
  )
}

export default HomeFeatures;